import {columnType} from 'utilsOld/helpers';

import {EditMode, SysFormFieldCollection} from 'utilsOld/systemObjects';
import {CombinedColumn, getColProps} from 'components/TableCoreOld/columns';
import {fields, system} from 'services/objects';
import {startInlineEditControl} from '../buttonColButtons';
import listToTree from '../../../utilsOld/listToTree';
import {getSysField} from '../../../utilsOld/sysFormUtils';

export default class ColumnsBuilder {
  /**
   * @param {Object} props
   * @param {SysFormFields[]} props.viewSource
   * @param {string} props.keyField
   * @param {number | undefined} props.checkFieldID
   * @param {string} props.systemKeyField
   * @param {number} props.keyFieldType
   * @param {number} props.isFixedColumnOrder
   * @param {number} props.isFixedOrder
   * @param {number} props.isShowInlineButtons
   * @param {string} props.regSetting
   * @param {string} props.objectName
   * @param {string} props.formKey
   * @param {number} props.maxLineCount
   * @param {number} props.editMode
   * @param {boolean} props.isNestedTable
   * @param {string | null} props.fixedOrderField
   * @param {CombinedColumnFields} props.combinedColumnFields
   * @param {string | null} props.parentFormID
   * @param {string | undefined} props.sysParentFieldName - родительское поле, только для дерева
   * @param {number | undefined} props.parentFieldType - тип родительского поля, только для дерева
   * @param {string[]} props.forbidFiltering - массив строк ObjectFieldID.Name
   */
  constructor({
    viewSource,
    keyField,
    systemKeyField,
    keyFieldType,
    regSetting,
    parentFormID,
    objectName,
    forbidFiltering,
    isFixedColumnOrder,
    isFixedOrder,
    isShowInlineButtons,
    combinedColumnFields,
    editMode,
    isNestedTable,
    maxLineCount,
    fixedOrderField,
    sysParentFieldName,
    parentFieldType,
    formKey,
    checkFieldID
  }) {
    this.viewSource = viewSource;
    this.keyField = keyField;
    this.systemKeyField = systemKeyField;
    this.keyFieldType = keyFieldType;
    this.regSetting = regSetting;
    this.isFixedColumnOrder = isFixedColumnOrder;
    this.isShowInlineButtons = isShowInlineButtons;
    this.isFixedOrder = isFixedOrder;
    this.objectName = objectName;
    this.maxLineCount = maxLineCount;
    this.editMode = new EditMode(editMode);
    this.isNestedTable = isNestedTable;
    this.parentFormID = parentFormID;
    this.forbidFiltering = forbidFiltering;
    this.sysParentFieldName = sysParentFieldName;
    this.parentFieldType = parentFieldType;
    this.fixedOrderField = fixedOrderField;
    this.formKey = formKey;
    this.combinedColumnFields = combinedColumnFields;
    this.checkFieldID = checkFieldID;

    if (
      combinedColumnFields.treeCheckField ||
      combinedColumnFields.treeIconField ||
      combinedColumnFields.treeTextField
    ) {
      this.combinedColumn = new CombinedColumn(
        combinedColumnFields,
        this.maxLineCount,
        this.isNestedTable,
        this.viewSource,
        this.parentFormID,
        this.formKey,
        forbidFiltering,
        this.checkFieldID
      ).prop();
    }
  }

  createColumn = formField => {
    const ColumnClass = getColProps(formField, this.editMode);

    const col = new ColumnClass({
      field: formField,
      keyField: this.keyField,
      regSetting: this.regSetting,
      isFixedColumnOrder: this.isFixedColumnOrder,
      isFixedOrder: this.isFixedOrder,
      objectName: this.objectName,
      isNestedTable: this.isNestedTable,
      maxLineCount: this.maxLineCount,
      viewSource: this.viewSource,
      parentFormID: this.parentFormID,
      forbidFiltering: this.forbidFiltering,
      fixedOrderField: this.fixedOrderField,
      formKey: this.formKey,
      checkFieldID: this.checkFieldID
    });

    return col.prop();
  };

  /**
   * @returns {Column[]}
   */
  prepareColumns() {
    const combinedFieldsArr = Object.values(this.combinedColumnFields).filter(fld => !!fld);
    //создается массив ids из комбинированных строк
    const combinedIdsArr = combinedFieldsArr.map(field => field[fields.ID]);
    let simpleFieldsArr = this.viewSource.filter(field => !combinedIdsArr.includes(field[fields.ID]));

    let prepared = new SysFormFieldCollection(simpleFieldsArr).toArrayOfInstances().map(this.createColumn);

    if (this.combinedColumn) {
      prepared.push(this.combinedColumn);
    }

    prepared.push(this.createSystemColumn());
    prepared.push(this.createColumnSpacer());
    prepared.push(this.createButtonColumn());

    if (this.sysParentFieldName) {
      prepared.push(this.createTreeSystemParentColumn());
    }

    return prepared;
  }

  createColumnSpacer() {
    return {
      allowEditing: false,
      allowFiltering: false,
      allowFixing: false,
      allowGrouping: false,
      allowHiding: false,
      allowReordering: false,
      allowResizing: true,
      groupParentID: null,
      allowExporting: false,
      name: getSysField(system.ColumnSpacer),
      showInColumnChooser: false,
      userData: {
        groupID: null,
        isSystemColumn: true
      },
      visible: true,
      minWidth: 1,
      visibleIndex: 99999
    };
  }

  createButtonColumn() {
    return {
      allowEditing: false,
      allowFiltering: false,
      allowFixing: false,
      allowGrouping: false,
      allowHiding: false,
      allowReordering: false,
      allowResizing: false,
      buttons: startInlineEditControl,
      cssClass: 'dx-command-edit d5-buttons-panel',
      fixed: true,
      fixedPosition: 'right',
      groupParentID: null,
      minWidth: 70,
      name: system.ButtonsColumn,
      showInColumnChooser: false,
      type: 'buttons',
      userData: {
        groupID: null,
        isSystemColumn: true,
        maxLineCount: this.maxLineCount
      },
      visible: this.isShowInlineButtons && this.editMode.isInline,
      visibleIndex: 99999,
      width: 70
    };
  }

  createSystemColumn() {
    return {
      allowEditing: false,
      allowFiltering: false,
      allowSorting: false,
      dataField: this.systemKeyField,
      name: this.systemKeyField,
      dataType: columnType(this.keyFieldType),
      showInColumnChooser: false,
      groupParentID: null,
      // временная мера, опция ломает рендеринг дерева
      // подождем фикса ДХ
      // пока выключаем асинк рендер колонок для дерева
      // таск: https://teamtrack.macc.com.ua/view.php?id=66681
      // renderAsync: true,
      userData: {
        groupID: null,
        isSystemColumn: true
      },
      visible: false
    };
  }

  createTreeSystemParentColumn() {
    return {
      dataField: this.sysParentFieldName,
      name: this.sysParentFieldName,
      dataType: columnType(this.parentFieldType),
      visible: false,
      allowEditing: false,
      allowFiltering: false,
      allowSorting: false,
      showInColumnChooser: false,
      userData: {
        groupID: null,
        isSystemColumn: true
      }
    };
  }

  /**
   * @param {SysFormGroup[]} groups
   * @returns {GridColumn[]}
   */
  sysGroupsToColumn(groups = []) {
    return groups.map(group => ({
      caption: group.Title,
      hint: group.Hint,
      docUrl: group.DocURL,
      name: group.Name,
      ID: group.ID,
      groupID: group.ID,
      helpArticleGuid: group.HelpArticleGuid,
      groupParentID: group.ParentGroupID,
      visible: !!group.IsVisible,
      visibleIndex: group.Order,
      width: group.Width,
      showInColumnChooser: true,
      columns: [],
      alignment: 'center',
      isCustomizable: !!group.IsCustomizable,
      headerCellTemplate: (textEl, {column}) => {
        textEl.innerText = column.caption;
        // const dragIcon = document.createElement('i');
        // dragIcon.className = 'drag-icon';
        // textEl.insertAdjacentElement('beforebegin', dragIcon);
      }
    }));
  }

  /**
   * @param {GridColumn[]} columns - Массив содержит колонки и групп. Группы не содержат свойство userData
   * @param {GridColumn[]} groups
   * @returns {*}
   */
  buildGroups(columns, groups = []) {
    const onlyColumns = columns.filter(col => col.userData);

    if (!groups.length) {
      // якщо groups(Sys_FormGroups) пусті то повертаємо тільки columns
      return onlyColumns;
    }
    const onlyGroups = columns.filter(col => !col.userData);
    const findLSGroup = groupID => onlyGroups.find(col => col.groupID === groupID);

    let loadedGroups = groups.map(group => {
      let gr = findLSGroup(group.ID);
      return {
        ...group,
        visible: gr?.visible ?? group.visible,
        visibleIndex: gr?.visibleIndex ?? group.visibleIndex,
        width: gr?.width ?? group.width
      };
    });

    return listToTree({
      list: [...onlyColumns, ...loadedGroups],
      childProp: 'columns',
      parentProp: 'groupParentID',
      sortByProp: 'visibleIndex',
      keyProp: 'ID'
    });
  }
}

import {AbstractStateStorage, StorageStringified} from './AbstractStateStorage';
import {system} from '../../services/objects';

export default class AppStateStorage extends AbstractStateStorage {
  constructor({userID}: {userID?: number}) {
    super({userID: userID!});
  }

  protected calcKey(): string {
    return this._userID + '-' + system.USER_SETTINGS.APP_SETTINGS;
  }

  get lsName(): string {
    return this.calcKey();
  }

  static lsName(userID?: number) {
    const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}');
    return new AppStateStorage({userID: currentUser.UserID || userID}).lsName;
  }

  exportAll(): Promise<StorageStringified> {
    return Promise.resolve([[this.lsName, this._storage.getItem(this.lsName) ?? '{}']]);
  }

  async importAll(states: StorageStringified = []): Promise<void> {
    for (const [, state] of states) {
      this._storage.setItem(this.lsName, state);
    }
  }
}

import dayjs from 'dayjs';
import {DateOrNothing} from 'components/newReactFormFields/FilterDateRange/types';
import DateSelectMode, {DATE_MODE} from './systemObjects/DateSelectMode';
import KeyboardEventHelper from './KeyboardEventHelper';
import {CalendarPopoverRef} from '../components/newReactFormFields/CalendarPopover';

export const dateToWeekRange = (date: DateOrNothing): DateOrNothing[] => {
  if (!date) return [];

  const valueDayJs = dayjs(date);
  const currentWeekDay = valueDayJs.day();
  // sunday
  if (currentWeekDay === 0) return [valueDayJs.day(-6).toDate(), date];

  return [valueDayJs.day(1).toDate(), valueDayJs.day(7).toDate()];
};

export const dateToMonthRange = (date: DateOrNothing): DateOrNothing[] => {
  if (!date) return [];
  if (Array.isArray(date)) {
    date = date[0];
  }
  const valueDayJs = dayjs(date);
  const startOfMonth = valueDayJs.startOf('month').toDate();
  const endOfMonth = valueDayJs.endOf('month').toDate();
  return [startOfMonth, endOfMonth];
};

export type DxDateZoomLevel = 'century' | 'decade' | 'month' | 'year';
export const minMaxZoomLevel = (
  dateMode: DATE_MODE
): {
  maxZoomLevel: DxDateZoomLevel;
  minZoomLevel: DxDateZoomLevel;
} => {
  if (DateSelectMode.isMonth(dateMode)) {
    return {
      minZoomLevel: 'decade',
      maxZoomLevel: 'year'
    };
  }

  return {
    minZoomLevel: 'century',
    maxZoomLevel: 'month'
  };
};
export type popoverPeriodRef<T> = {
  current: T | null;
};
export const openCustomPeriodPopup = (event: any, ref: popoverPeriodRef<CalendarPopoverRef>) => {
  const {rangePopoverOpen} = ref.current || {};
  if (KeyboardEventHelper.altKey(event) && KeyboardEventHelper.arrowDownKey(event)) {
    rangePopoverOpen?.(true);
  }
};

export const setFirstDateOfMonth = (date: string | Date): Date => {
  if (date == null) return date;
  return dayjs(date).date(1).toDate();
};

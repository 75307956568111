import * as DS from 'components/Layout/LayoutItems/LayoutFilter/ReactOperationWidget/datasources';
import FieldEditorType from 'utilsOld/FieldEditorType';
import {FIELD_EDITOR_TYPE} from '../services/interfaces/global-interfaces';
import {FilterField} from './systemObjects';

export default abstract class FilterOperationDataSource {
  static getDefaultDataSource(filterFieldInst: FilterField) {
    const type = new FieldEditorType(filterFieldInst.d5EditorType());
    const {isMonthDateMode, isWeekDateMode} = filterFieldInst;

    switch (type.toString()) {
      case FIELD_EDITOR_TYPE.TEXT:
      case FIELD_EDITOR_TYPE.HTML_EDITOR:
        return DS.string_DS;
      case FIELD_EDITOR_TYPE.NUMBER:
        return DS.number_DS;
      case FIELD_EDITOR_TYPE.SELECT:
        return DS.select_DS;
      case FIELD_EDITOR_TYPE.MULTI_SELECT:
        return DS.multiselect_DS;
      case FIELD_EDITOR_TYPE.DATE:
      case FIELD_EDITOR_TYPE.DATETIME:
        if (isMonthDateMode || isWeekDateMode) return [DS.createEqualItem()];
        return DS.date_DS;
      case FIELD_EDITOR_TYPE.RANGE_SLIDER:
        return DS.slider_DS;
      default:
        return [DS.createEqualItem()];
    }
  }

  static getDataSource(filterFieldInst: FilterField) {
    const {isCustomConditionList, customConditionList = []} = filterFieldInst;
    if (isCustomConditionList) return DS.createCustomConditionItems(customConditionList);
    return FilterOperationDataSource.getDefaultDataSource(filterFieldInst);
  }
}

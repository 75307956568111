import {MaximizeFormState, MinimizeFormState} from '../settingsStorage/FormStateManager';
import {booleanToNumber, numberToBoolean} from './utils';
import {maximizeTBButtons, minimizeTBButtons} from './convertTBButtons';
import {isEmptyValue} from '../../services/SecondaryMethods/typeUtils';
import {USmallInt} from '../../services/interfaces/global-interfaces';

export const minimizeFormState = (state: MaximizeFormState): MinimizeFormState => {
  const isAlreadyMinimized = Object.keys(state).some((key) => ['l', 'fo'].includes(key));

  if (isAlreadyMinimized) return state as unknown as MinimizeFormState;

  return {
    l: isEmptyValue(state.layout)
      ? undefined
      : {
          g: state.layout.groups
        },
    w: state.width,
    h: state.height,
    fo: booleanToNumber(state.firstOpen) as USmallInt,
    tb: state.tbButtons ? minimizeTBButtons(state.tbButtons) : undefined,
    t: state.tabs,
    fd: state.fieldsDimensions,
    smd: state.sidebarModalDimensions,
    qc: state['qr-cam'],
    trk: state.totalRowKind,
    trv: booleanToNumber(state.totalRowVisible),
    wbt: state.widthByTitle
  };
}

export const maximizeFormState = (state: MinimizeFormState): MaximizeFormState => {
  return {
    layout: {
      groups: state.l?.g,
    },
    width: state.w,
    height: state.h,
    firstOpen: numberToBoolean(state.fo) as boolean,
    tbButtons: state.tb ? maximizeTBButtons(state.tb) : [],
    tabs: state.t,
    fieldsDimensions: state.fd,
    sidebarModalDimensions: state.smd,
    'qr-cam': state.qc,
    totalRowKind: state.trk,
    totalRowVisible: numberToBoolean(state.trv),
    widthByTitle: state.wbt
  }
}

import {getRow} from '../elems/listForm/D5Row';
import {stylesRowWrapper} from 'middlewares/userScript/elems/stylesWrapper';
import {fields} from '../../../services/objects';
import {runFormScriptSync} from '../utils';
import {SysFormWrapper} from '../../../utilsOld/systemObjects';
import {getEvent} from '../utils/getEvent';
import {BaseUserEventArgs} from './types';
import React from 'react';
import {USER_EVENT_TYPE} from '../../../services/interfaces/global-interfaces';
import {SysForm} from '../../../services/interfaces/sysObjects';


type CustomizeRowStyles = Record<number, {styles: React.CSSProperties}>;


interface OnCustomizeRowArgs extends Omit<BaseUserEventArgs, 'processedForm'> {
  name: string;
  rowIndex: number;
  isSelected: boolean;
  isExpanded?: boolean;
  trHtmlElement: HTMLElement[];
  customizeRow: CustomizeRowStyles;
  rowData: Record<any, any>;
  sysForm: SysForm;
}

export const onCustomizeRow = ({
  name,
  rowData,
  sysForm,
  rowIndex,
  isSelected,
  isExpanded,
  trHtmlElement,
  customizeRow,
}: OnCustomizeRowArgs) => {
  const eventType = USER_EVENT_TYPE.ON_CUSTOMIZE_ROW;
  const eventName = getEvent({sysForm, name, eventType});
  const wrapper = new SysFormWrapper(sysForm);

  const keyFld = sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  const row = getRow(rowData, keyFld);

  const rowStyles = {};

  const result = runFormScriptSync({
    form: {name: wrapper.name},
    core: null,
    eventName,
    rowStyles,
    args: [
      {
        row,
        style: stylesRowWrapper(trHtmlElement, rowStyles),
        rowIndex,
        isSelected,
        isExpanded,
        trHtmlElement
      }
    ]
  });

  customizeRow[row.key] = {
    styles: {...rowStyles},
  };

  return result;
}

import FormStateManager from 'utilsOld/settingsStorage/FormStateManager';
import {isArray} from 'services/SecondaryMethods/typeUtils';
import {StorageStringified} from './AbstractStateStorage';
import TableStateStorage from './TableStateStorage';
import FilterStateStorage from './FilterStateStorage';
import DocFilterStateStorage from './DocFilterStateStorage';
import AppStateStorage from './AppStateStorage';
import {formatMessage} from '../formatMessage';
import {Messages} from '../../services/lang/messages';
import {parseAndConvertState} from '../storageConverter/utils';
import {minimizeFormState} from '../storageConverter/convertFormState';
import {minimizeFilters} from '../storageConverter/convertFilters';
import {minimizeVisibleFilters} from '../storageConverter/convertVisibleFilters';

type ExportData = {
  formStorage: StorageStringified;
  tablesStorage: StorageStringified;
  filtersStorage: StorageStringified;
  docFilterStorage: StorageStringified;
  appStorage: StorageStringified;
};

export default class SettingsStorage {
  static async exportAll(userID: number): Promise<ExportData> {
    const [formStorage, tablesStorage, filtersStorage, docFilterStorage, appStorage] = await Promise.all([
      SettingsStorage.getFormStateStorage({userID, formKey: ''}).exportAll(),
      SettingsStorage.getTableStateStorage({userID, formKey: ''}).exportAll(),
      SettingsStorage.getFilterStateStorage({userID, formKey: ''}).exportAll(),
      SettingsStorage.getDocFilterStateStorage({userID, formKey: ''}).exportAll(),
      SettingsStorage.getAppStateStorage(userID).exportAll()
    ]);

    return {
      formStorage,
      tablesStorage,
      filtersStorage,
      docFilterStorage,
      appStorage
    };
  }

  static async importAll(userID: number, importData: ExportData): Promise<unknown> {
    await Promise.all([
      SettingsStorage.validateAppConfig(importData),
      SettingsStorage.validateFormConfig(importData),
      SettingsStorage.validateTableConfig(importData),
      SettingsStorage.validateDocFilterConfig(importData),
      SettingsStorage.validateFilterConfig(importData)
    ]);

    return Promise.all([
      SettingsStorage.getFormStateStorage({userID, formKey: ''}).importAll(importData.formStorage, (state: string) =>
        parseAndConvertState(state, minimizeFormState)
      ),
      SettingsStorage.getTableStateStorage({userID, formKey: ''}).importAll(importData.tablesStorage),
      SettingsStorage.getFilterStateStorage({userID, formKey: ''}).importAll(
        importData.filtersStorage,
        (state: string) => parseAndConvertState(state, minimizeFilters)
      ),
      SettingsStorage.getDocFilterStateStorage({userID, formKey: ''}).importAll(
        importData.docFilterStorage,
        (state: string) => parseAndConvertState(state, minimizeVisibleFilters)
      ),
      SettingsStorage.getAppStateStorage(userID).importAll(importData.appStorage)
    ]);
  }

  static getFormStateStorage(args: {userID: number; formKey: string}) {
    return new FormStateManager(args);
  }

  static getTableStateStorage(args: {userID: number; formKey: string}) {
    return new TableStateStorage(args);
  }

  static getFilterStateStorage(args: {userID: number; formKey: string}) {
    return new FilterStateStorage(args);
  }

  static getDocFilterStateStorage(args: {userID: number; formKey: string}) {
    return new DocFilterStateStorage(args.userID, args.formKey);
  }

  static getAppStateStorage(userID: number) {
    return new AppStateStorage({userID});
  }

  static async validateFormConfig(config: ExportData) {
    if (!config.formStorage) throw new Error(formatMessage(Messages.Errors.InvalidConfigNoProperty, ['formStorage']));
    if (!isArray(config.formStorage) || config.formStorage.some(item => item.length !== 2))
      throw new Error(formatMessage(Messages.Errors.InvalidConfigWrongStructure, ['formStorage']));
  }

  static async validateTableConfig(config: ExportData) {
    if (!config.tablesStorage)
      throw new Error(formatMessage(Messages.Errors.InvalidConfigNoProperty, ['tablesStorage']));
    if (!isArray(config.tablesStorage) || config.tablesStorage.some(item => item.length !== 2))
      throw new Error(formatMessage(Messages.Errors.InvalidConfigWrongStructure, ['tablesStorage']));
  }

  static async validateFilterConfig(config: ExportData) {
    if (!config.filtersStorage)
      throw new Error(formatMessage(Messages.Errors.InvalidConfigNoProperty, ['filtersStorage']));
    if (!isArray(config.filtersStorage) || config.filtersStorage.some(item => item.length !== 2))
      throw new Error(formatMessage(Messages.Errors.InvalidConfigWrongStructure, ['filtersStorage']));
  }

  static async validateDocFilterConfig(config: ExportData) {
    if (!config.docFilterStorage)
      throw new Error(formatMessage(Messages.Errors.InvalidConfigNoProperty, ['docFilterStorage']));
    if (!isArray(config.docFilterStorage) || config.docFilterStorage.some(item => item.length !== 2))
      throw new Error(formatMessage(Messages.Errors.InvalidConfigWrongStructure, ['docFilterStorage']));
  }

  static async validateAppConfig(config: ExportData) {
    if (!config.appStorage) throw new Error(formatMessage(Messages.Errors.InvalidConfigNoProperty, ['appStorage']));
    if (!isArray(config.appStorage) || config.appStorage.some(item => item.length !== 2))
      throw new Error(formatMessage(Messages.Errors.InvalidConfigWrongStructure, ['appStorage']));
  }
}

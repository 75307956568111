import {fields} from 'services/objects';
import TableCacheDataSource from './TableCacheDataSource';
import {createFakeRootID, isFakeRootID} from 'utilsOld/helpers';
import {addItemsExpr, prepareSourceRow} from './utils';
import {getSysField, NODE_HAS_CHILDREN_FIELD} from 'utilsOld/sysFormUtils';
import {isDefined, isEmptyObject, isEmptyValue, isNull, isUndefined} from 'services/SecondaryMethods/typeUtils';
import {SysFormFilterFields} from 'services/interfaces/sysObjects';
import {ExtendedLoadOptions, LoadResult} from './consts';
import {DefaultTreeExpand, PaginationType, SysFormWrapper} from 'utilsOld/systemObjects';
import {HierarchyRequestKind} from 'services/interfaces/global-interfaces';
import PaginationTypeUtil from 'utilsOld/systemObjects/PaginationType';
import {dxFilterToApi} from 'services/SecondaryMethods/filterUtils';

export default class TreeCacheDataSource extends TableCacheDataSource {
  private _parentFieldName: string;
  private sysParentFieldName: string;
  private _isRootShown: boolean;
  private _hierarchyMap: Record<string, HierarchyRequestKind> = {};
  private loadingFakeRowsChildren: boolean = false;
  private _fakeRootID?: string;
  private _rootName: string = '';
  private _defaultTreeExpandLevel: number;
  private _defaultTreeExpandKind: DefaultTreeExpand;

  constructor(sysForm: SysFormWrapper, formKey: string) {
    super(sysForm, formKey);

    this._parentFieldName = this.sysForm.parentField!;
    this._defaultTreeExpandLevel = this.sysForm.defaultTreeExpandLevel || 0;
    this.sysParentFieldName = getSysField(this._parentFieldName);
    this._defaultTreeExpandKind = this.sysForm.defaultTreeExpandKind;

    // @ts-ignore
    this._rootName = this.sysForm.rootName;
    this._isRootShown = this.sysForm.isShowRoot;

    if (this._isRootShown) {
      // @ts-ignore
      this._fakeRootID = createFakeRootID(this.sysForm.keyFieldType);
    }

    this.createHierarchyFieldsMap();
  }

  createHierarchyFieldsMap() {
    const isFieldUseHierarchyReq = (filterItem: SysFormFilterFields) => filterItem[fields.IsUseHierarchyRequest];

    this._hierarchyMap = this.sysForm.filterFields.filter(isFieldUseHierarchyReq).reduce(
      (acc, filterItem) => {
        // фільтри для ієрархічного запиту можуть братись по імені object field, а якщо його немає, то по name
        const name = filterItem[fields.ObjectFieldID_Name] ?? filterItem[fields.Name];
        acc[name] = filterItem[fields.HierarchyRequestKind]!;
        return acc;
      },
      {} as Record<string, HierarchyRequestKind>
    );
  }

  protected forcedFilter(outFilter: Record<string, any> = {}) {
    outFilter = super.forcedFilter(outFilter);

    for (let key in this.masterFilter) {
      if (isFakeRootID(this.masterFilter[key])) {
        outFilter[key] = null;
      }
    }

    return outFilter;
  }

  processData(data: Record<string, any>[]) {
    return data.map(row => {
      const rowData = prepareSourceRow({
        row,
        viewSource: this.sysForm.formFields,
        columnNames: this.columnNames,
        sysForm: this.sysForm.asRaw()
      });

      if (this.loadingFakeRowsChildren && rowData[this._parentFieldName] == null) {
        rowData[this.sysParentFieldName] = this._fakeRootID;
      }
      return rowData;
    });
  }

  createFakeRow() {
    let row: Record<string, any> = {};
    row[this.systemKeyField] = this._fakeRootID;
    row[this.keyField] = this._fakeRootID;

    if (this.sysForm.formFields.some(v => v[fields.ObjectFieldID_Name] === fields.Name)) {
      //Роберт сказал что всегда нужно писать в поле Name, если такое есть
      row[fields.Name] = this._rootName;
    }

    return row;
  }

  /**
   * Берет параметры фильтров(в формате как для запроса) и генерирует дополнительные
   * параметры для запроса: поля IsUseHierarchyRequest и HierarchyRequestKind
   * @param {*} filters
   */
  private getHierarchyParams(filters: Record<string, any>) {
    // Ищем первый попавшийся фильтр, см. доку (https://wiki.erp-director.com/d5wiki/pages/viewpage.action?pageId=1546450)
    const hierarchyKind = this.hierarchyRequest(filters);

    let result: {
      [fields.HierarchyHasChildRowsColumn]?: string;
      [fields.HierarchyParentColumn]?: string;
      [fields.HierarchyRequestKind]?: HierarchyRequestKind;
      [fields.HierarchyChildLevel]?: number;
    } = {};

    const paginationAuto = PaginationTypeUtil.isAuto(this.paginationType);

    if (paginationAuto) {
      result[fields.HierarchyParentColumn] = this._parentFieldName;
      result[fields.HierarchyHasChildRowsColumn] = NODE_HAS_CHILDREN_FIELD;
      if (this._defaultTreeExpandKind.isExpandToLevel()) {
        result[fields.HierarchyRequestKind] = HierarchyRequestKind.allChild;
      }
      if (this._defaultTreeExpandLevel && this.preloading) {
        result[fields.HierarchyChildLevel] = this._defaultTreeExpandLevel;
      }
    }

    if (isDefined(hierarchyKind)) {
      result[fields.HierarchyParentColumn] = this._parentFieldName;
      result[fields.HierarchyRequestKind] = this._hierarchyMap[hierarchyKind!];
      /**Если установлен HierarchyRequestKind, то наличие чилдренов считается на клиенте*/
      delete result[fields.HierarchyHasChildRowsColumn];
    }

    return result;
  }

  private hierarchyRequest(filters: Record<string, any>) {
    if (isEmptyObject(this._hierarchyMap)) {
      return;
    }

    return Object.keys(filters).find(filterKey => {
      return this._hierarchyMap[filterKey] && !isUndefined(filters[filterKey]);
    });
  }

  public getRequestObj(sort: {selector: string; desc: boolean}[], parentIds: any[]) {
    const filters = this.prepareFilter({parentIds});
    const hierarchyParams = this.getHierarchyParams(filters) || {};
    const aSort = super.prepareSortData(sort);

    return {
      Filters: filters,
      Sorts: aSort.length ? aSort : undefined,
      Page: -1,
      ...hierarchyParams
    };
  }

  private parentFakeRootFilter(filter: Record<string, any>, parentIds: any[]) {
    this.loadingFakeRowsChildren = (() => {
      let parentFilter = filter[this._parentFieldName]['='];
      return Array.isArray(parentFilter) && parentFilter.includes(this._fakeRootID);
    })();

    if (this.loadingFakeRowsChildren && parentIds.length === 1 && parentIds[0] === this._fakeRootID) {
      filter[this._parentFieldName]['='] = null;
    }

    return JSON.parse(JSON.stringify(filter).replace(new RegExp(`(,?${this._fakeRootID},?)`), ''));
  }

  private parentFieldFilter(filter: Record<string, any>, loadOptionsParentIds: any[]) {
    if (PaginationType.isNone(this.paginationType) || this.hierarchyRequest(filter)) return filter;

    let result = filter;

    let parentIds: any[] = loadOptionsParentIds.filter(isDefined);
    let withRoot = loadOptionsParentIds.length !== parentIds.length;

    if (parentIds.length) {
      result[this._parentFieldName] = {'=': parentIds};

      if (withRoot) {
        result[this._parentFieldName]['or'] = {[this._parentFieldName]: {'=': null}};
      }
    } else {
      result[this._parentFieldName] = {'=': null};
    }

    result = this.parentFakeRootFilter(result, loadOptionsParentIds);

    if (isEmptyValue(result[this._parentFieldName]['='])) {
      result[this._parentFieldName]['='] = null;
    }

    return result;
  }

  prepareFilter({parentIds = []}: Pick<ExtendedLoadOptions, 'parentIds'>) {
    let outFilter = super.prepareFilter(parentIds);
    return this.parentFieldFilter(outFilter, parentIds);
  }

  /**
   * В деревьях нужно всегда делать запрос на все записи
   */
  isLoadAll(): boolean {
    return true;
  }

  isHierarchyRequest() {
    for (let key in this._hierarchyMap) {
      if (this.storeFilters[key].value) {
        return true;
      }
    }
    return false;
  }

  // public loadAllParentNodes({Filters}: {Filters: Record<any, any>}) {
  //   return this.loadData({
  //     Filters,
  //     HierarchyRequestKind: HierarchyRequestKind.allParent,
  //     HierarchyParentColumn: this._parentFieldName,
  //     Page: -1,
  //   });
  // }

  private async loadNavigateMode(filter: any[]): Promise<LoadResult> {
    if (!isDefined(filter[2])) return this.emptyResult();
    const Filters: Record<string, any> = dxFilterToApi(filter) ?? {};
    if (Filters.hasOwnProperty(this.sysParentFieldName)) {
      Filters[this._parentFieldName] = Filters[this.sysParentFieldName];
      delete Filters[this.sysParentFieldName];
    }

    if (Filters.hasOwnProperty(this.systemKeyField)) {
      Filters[this.keyField] = Filters[this.systemKeyField];
      delete Filters[this.systemKeyField];
    }

    const {data} = await this.loadData({
      Filters,
      Page: -1
    });

    return {
      ...this.emptyResult(),
      data
    };
  }

  private updateParentRowHasChildren(row: Record<string, any>) {
    if (row[this.sysParentFieldName]) {
      // Дана логіка потрібна для добавлення прапорця, що батьківський елемент має дочірні елементи,
      // що дозволить dx коректно відрендерити дерево
      const data = this.cachedData.data;
      const parent = data.find(dataRow => dataRow[this.systemKeyField] === row[this.sysParentFieldName]);

      parent && (parent[NODE_HAS_CHILDREN_FIELD] = true);
    }
  }

  private updateCurrentRowHasChildren(row: Record<string, any>) {
    if (row.hasOwnProperty(NODE_HAS_CHILDREN_FIELD)) {
      return;
    }
    const data = this.cachedData.data;
    row[NODE_HAS_CHILDREN_FIELD] = data.some(
      dataRow => row[this.systemKeyField] === dataRow[this.sysParentFieldName] || dataRow[NODE_HAS_CHILDREN_FIELD]
    );
  }

  protected modifyCacheAfterUpdate(row: Record<string, any>) {
    this.updateParentRowHasChildren(row);
    this.updateCurrentRowHasChildren(row);
    super.modifyCacheAfterUpdate(row);
  }

  protected modifyCacheAfterInsert(row: Record<string, any>) {
    if (row[this.sysParentFieldName]) {
      // Дана логіка потрібна для добавлення прапорця, що батьківський елемент має дочірні елементи,
      // що дозволить dx коректно відрендерити дерево
      const data = this.cachedData.data;
      const parent = data.find(dataRow => dataRow[this.systemKeyField] === row[this.sysParentFieldName]);

      parent && (parent[NODE_HAS_CHILDREN_FIELD] = true);
    }
    super.modifyCacheAfterInsert(row);
  }

  async load(loadOptions: ExtendedLoadOptions) {
    if (this._dataSource.navigateToNodeMode) {
      return this.loadNavigateMode(loadOptions.filter);
    }

    const isPaginationTypeNone = PaginationType.isNone(this.paginationType);

    const {parentIds = [null]} = loadOptions;
    const shouldAddFake = Array.isArray(parentIds) && parentIds.includes(null) && this._isRootShown;
    // если в массиве parentIds нет ID фейковой группы, при этом присутствует null,
    // это значит, что фейковая группа не открыта
    const loadingFakeRow = shouldAddFake && parentIds && !parentIds.includes(this._fakeRootID);

    // если мы открываем фейковую группу и у нас при этом в parentIds
    // есть другие элементы, то неправильно сформируется фильтр
    if (parentIds.includes(this._fakeRootID) && parentIds.length > 1) {
      loadOptions.parentIds = [this._fakeRootID];
    }

    let result = this.emptyResult();

    if (loadingFakeRow && !isPaginationTypeNone) {
      result.data.push(this.createFakeRow());
      // это значит что нажали на фейковую строку чтобы она расскрылась
    } else {
      result = await super.load(loadOptions);
      //скорее всего это сортировка или фильтр или обновление после редактирования строки
      //нужно добавить фейковую строку
      if (shouldAddFake) {
        if (!result.data.some(d => d[this.keyField] === this._fakeRootID)) {
          const fakeRow = this.createFakeRow();
          result.data.push(fakeRow);
        }
      }
    }
    if (this.isHierarchyRequest()) {
      result.data = addItemsExpr(result.data, this._parentFieldName, this.systemKeyField);
    }
    return result;
  }

  protected shouldEmitDataLoad(loadOptions: ExtendedLoadOptions): boolean {
    const {parentIds} = loadOptions;
    return !parentIds || (Array.isArray(parentIds) && parentIds.length === 1 && isNull(parentIds[0]));
  }

  loadRegular() {
    // @ts-ignore
    return this.loadPaginationNone.apply(this, arguments);
  }
}

export const setUserApplication = (app: string, hash: string) => {
  // @ts-ignore
  if (!window.userApplications) {
    // @ts-ignore
    window.userApplications = {};
  }

  // @ts-ignore
  window.userApplications[app] = {hash};
};

export const getUserApplication = (app: string): {hash: string} | undefined => {
  // @ts-ignore
  return window.userApplications?.[app];
};

import {createSelector} from 'reselect';
import {Map} from 'immutable';
import {specificForm} from 'services/currentForms/specificForm';
import {getFormKey} from '../SecondaryMethods/getFormKey';
import {CurrentFormState} from './types';
import {getAllSubTablesWithCache} from './actionUtils';
import {RootState} from '../../store';
import {ELayoutType, ILayoutItem, ISubFormOptions} from '../SecondaryMethods/formItems/itemInterfaces';

export const currentFormStateSelector = (state: RootState) => state.currentForm as Map<string, CurrentFormState>;
const currentFormSelector = (state: RootState, formId: string): CurrentFormState =>
  state.currentForm.get(formId) || specificForm;

export const makeCurrFormSelector = () => {
  return currentFormSelector;
};

export const makeParentFormSelector = (formId: string) =>
  createSelector(currentFormStateSelector, curForms => {
    const current = curForms.find(curForm => String(curForm.actionFormId) === String(formId));
    return curForms.find(curForm => String(curForm.actionFormId) === String(current?.parentFormID));
  });

export const makeFormKeySelector = () => {
  return createSelector(
    currentFormStateSelector,
    (_: any, formID: string) => String(formID),
    (curForms, formID): string => {
      let form = curForms.get(formID);
      return form?.formKey || getFormKey(form!.actionFormId!, form!.parentFormID);
    }
  );
};
export const makeNestedDataSelector = () => {
  return createSelector(
    currentFormStateSelector,
    (
      _: any,
      props: {
        parentFormID: string;
        nestedFieldName: string;
      }
    ) => props,
    (curForms, {parentFormID, nestedFieldName}) => {
      if (parentFormID == null || !nestedFieldName) return undefined;
      return (curForms.get(String(parentFormID)) || specificForm).formData[nestedFieldName];
    }
  );
};

export const makeAllRelatedCurrentFormsSelector = (includeParent = true) => {
  let store = [] as CurrentFormState[];
  return createSelector(currentFormStateSelector, currentFormSelector, (curForms, parentForm) => {
    const subForms: ILayoutItem<ISubFormOptions>[] =
      parentForm.items?.filter(item => item.itemType === ELayoutType.SUBFORM) ?? [];

    const childForms = curForms
      .filter(c => subForms.some(item => item.options.formID === c.actionFormId))
      .toArray()
      .map(([_, value]) => value);

    let result = includeParent ? [parentForm, ...childForms] : childForms;

    if (result.some((item, i) => store[i] !== item)) {
      store = result;
    }

    return store;
  });
};

/**
 * Возвращает массив таблиц у которых есть cacheDataSource.
 * Используется для прелода данных в таблицы
 * @see loadAllSubFormsData
 */
export const allTablesItemsSelector = (state: RootState, formID: string) => {
  const _formID = String(formID);
  const curForms = currentFormStateSelector(state);

  const current = curForms.get(_formID);
  if (!current) return [];

  return getAllSubTablesWithCache(current.items, curForms);
};

const createSpecificPropSelector =
  <K extends keyof CurrentFormState>(propName: K) =>
  () => {
    return createSelector(
      currentFormStateSelector,
      (_: any, formID: string) => formID,
      (curForms, formID): CurrentFormState[K] => {
        return (curForms.get(String(formID)) || specificForm)[propName];
      }
    );
  };

export const makeEditableRowDataSelector = createSpecificPropSelector('editableRowData');
export const makeRowsPerPageSelector = createSpecificPropSelector('rowsPerPage');
export const makeParentFormFilterSelectorSelector = createSpecificPropSelector('parentFormFilter');
export const makeFormDataSelector = createSpecificPropSelector('formData');
export const makeIDSelector = createSpecificPropSelector('id');
export const makeTitleSelector = createSpecificPropSelector('title');
export const makeActionFormIDSelector = createSpecificPropSelector('actionFormId');
export const makeParentFormIDSelector = createSpecificPropSelector('parentFormID');
export const makeToolBarViewTypeSelector = createSpecificPropSelector('toolBarViewType');
export const makeItemsSelector = createSpecificPropSelector('items');
export const makeGroupsSelector = createSpecificPropSelector('groups');
export const makeDataSourceSelector = createSpecificPropSelector('dataSource');
export const makeClosePromiseSelector = createSpecificPropSelector('closePromise');
export const makeSelectedRowKeysSelector = createSpecificPropSelector('selectedRowKeys');
export const makeEventsSelector = createSpecificPropSelector('events');
export const makeViewModeSelector = createSpecificPropSelector('viewMode');
export const makeFormStatusSelector = createSpecificPropSelector('formStatus');
export const makeCreateModeSelector = createSpecificPropSelector('createMode');
export const makeIsFixedOrderSelector = createSpecificPropSelector('isFixedOrder');
export const makeOperationsParamsSelector = createSpecificPropSelector('operationsParams');
export const makeEventQueueSelector = createSpecificPropSelector('eventQueue');
export const makeFocusOnSelector = createSpecificPropSelector('focusOn');
export const makeFormTypeSelector = createSpecificPropSelector('formType');
export const makeFocusedItemSelector = createSpecificPropSelector('focusedItem');
export const makeIsShowTitleSelector = createSpecificPropSelector('isShowTitle');
export const makeSelectedRowsSelector = createSpecificPropSelector('selectedRows');
export const makeDataWorkerSelector = createSpecificPropSelector('dataWorker');
export const makeInitialFormDataSelector = createSpecificPropSelector('initialFormData');
export const makeButtonTypeSelector = createSpecificPropSelector('buttonType');
export const makeFirstOpenSelector = createSpecificPropSelector('firstOpen');
export const makeReadOnlySelector = createSpecificPropSelector('readOnly');
export const makeDisabledSelector = createSpecificPropSelector('disabled');
export const makeObjectNameSelector = createSpecificPropSelector('objectName');

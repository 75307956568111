import {getApplicationLang} from '../services/SecondaryMethods/userSettings';
import {LanguageCode} from '../services/interfaces/global-interfaces';
import {Messages} from '../services/lang/messages';
import {formatMessage} from './formatMessage';

const errorMessagesUA = {
  'Unexpected end of JSON input': 'Неочікуваний кінець JSON-вводу',
  'Unexpected token': 'Неочікуваний токен',
  'Unexpected string': 'Неочікуваний рядок',
  'Unexpected number': 'Неочікувана цифра',
  'Unexpected keyword': 'Неочікуване ключове слово',
  'Unexpected identifier': 'Неочікуваний ідентифікатор',
  'Unexpected template': 'Неочікуваний шаблон',
  'Unexpected punctuation': 'Неочікувана пунктуація',
  'Object property name missing': "Відсутнє ім'я властивості об'єкта",
  'Object property name invalid': "Недійсне ім'я властивості об'єкта",
  'Object property value invalid': "Недійсне значення властивості об'єкта",
  'Property name expected': "Очікувалось ім'я властивості",
  'Value could not be parsed': 'Значення не вдалося розібрати',
  'Comma or closing bracket expected': 'Очікувалась кома або закриваюча дужка',
  'Colon expected': 'Очікувався двокрапка',
  'End of input expected': 'Очікуваний кінець вводу',
  'Invalid escape character': 'Недійсний символ екранування',
  'Invalid Unicode escape sequence': 'Недійсна послідовність екранування Unicode',
  'Invalid escape sequence': 'Недійсна послідовність екранування',
  'Invalid number': 'Недійсний номер',
  'Missing exponent': 'Відсутній показник',
  'Multiple decimal points': 'Декілька десяткових роздільників',
  'Multiple minuses': 'Декілька мінусів',
  'Multiple pluses': 'Декілька плюсів',
  'No digits after exponent': 'Немає цифр після показника',
  'Unterminated string': 'Незакінчений рядок',
  'Unterminated comment': 'Незакінчений коментар',
  'Invalid JSON primitive': 'Недійсний примітив JSON',
  'Unexpected character': 'Неочікуваний символ'
} as const;

function parseJSONWithTranslation(jsonString: string) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    if (!(error instanceof SyntaxError)) {
      throw error;
    }
    const lang = getApplicationLang();
    const messages = lang === LanguageCode.ua ? errorMessagesUA : {};
    for (const messagesKey in messages) {
      if (error.message.includes(messagesKey)) {
        //@ts-ignore
        throw new SyntaxError(formatMessage(Messages.Errors.JsonParseError, [messages[messagesKey]]));
      }
    }
    throw new SyntaxError(formatMessage(Messages.Errors.JsonParseError, [error.message]));
  }
}

export default parseJSONWithTranslation;

import {SysFormFields} from 'services/interfaces/sysObjects';
import {isSysField} from 'utilsOld/sysFormUtils';
import {GridColumn} from 'components/TableCoreOld/column-interfaces';
import DynamicColumnCreator from 'services/tables/DynamicItemCreators/DynamicColumnCreator';
import {TVLocalStorageColumn, TVLocalStorageColumn as LSColumn} from 'components/TableCoreOld/TableView';
import {system} from 'services/objects';
import {isDefined} from 'services/SecondaryMethods/typeUtils';
import {createDynamicCalculateCellValue, createDynamicSetCellValue} from '../../services/tables/utils';
import {maximizeColumns, minimizeColumns} from '../../utilsOld/storageConverter/convertColumns';
import TableDB from '../../indexedDB/TableDB';
import {splitFormKey} from '../../services/SecondaryMethods/getFormKey';
import {createTableID, tableIdToFormKey} from '../../utilsOld/storageKeys';

function transformStorageColumnToGrid(storageColumn: LSColumn): Partial<GridColumn> {
  return {
    dataField: storageColumn.dataField,
    groupID: storageColumn.groupID,
    groupParentID: storageColumn.groupParentID,
    index: storageColumn.index,
    visible: storageColumn.visible,
    visibleIndex: storageColumn.visibleIndex,
    isVisibleOnEditDockPanel: storageColumn.isVisibleOnEditDockPanel,
    width: storageColumn.width,
    fixed: storageColumn.fixed,
    fixedPosition: storageColumn.fixedPosition,
    sortOrder: storageColumn.sortOrder,
    sortIndex: storageColumn.sortIndex
  };
}

function transformGridColumnToStorage(gridColumn: GridColumn): LSColumn {
  return {
    dataField: gridColumn.dataField,
    groupID: gridColumn.groupID,
    groupParentID: gridColumn.groupParentID,
    index: gridColumn.index,
    visible: gridColumn.userData?.wasHiddenByUser ? gridColumn.userData?.prevVisible : gridColumn.visible,
    visibleIndex: gridColumn.visibleIndex,
    isVisibleOnEditDockPanel: gridColumn.isVisibleOnEditDockPanel,
    width: gridColumn.width,
    fixed: gridColumn.fixed,
    sortOrder: gridColumn.sortOrder,
    sortIndex: gridColumn.sortIndex,
    fixedPosition: gridColumn.fixedPosition,
    isDynamicColumn: gridColumn.userData?.isDynamicColumn,
    userCreated: gridColumn.userData?.userCreated,
    isCombinedColumn: !!gridColumn.userData?.fieldChildren
  };
}

interface SaveArgs {
  viewSource: SysFormFields[];
  column: GridColumn;
}

interface Saver {
  (args: SaveArgs): LSColumn | void;
}

const saveGroup: Saver = (args: SaveArgs) => {
  const {column} = args;

  if (!column.groupID) return;

  return transformGridColumnToStorage(column);
};

const saveSystemCol: Saver = ({viewSource, column}: SaveArgs) => {
  const dataField = column.dataField || column.name;

  const isDynamicColumn = column?.userData?.isDynamicColumn;

  if (dataField === system.ButtonsColumn) return;

  if (dataField && isSysField(dataField) && !isDynamicColumn) {
    return;
  }

  if (!isDynamicColumn || !dataField) return;

  const baseField = DynamicColumnCreator.getBaseFieldName(dataField);
  let field = viewSource.find(f => f.Name === baseField);
  return field && transformGridColumnToStorage(column);
};

const saveCol: Saver = ({viewSource, column}: SaveArgs) => {
  const dataField = column.dataField || column.name;
  let field = viewSource.find(f => f.Name === dataField);
  return field && transformGridColumnToStorage(column);
};

function loadDynamicColumn(loadedCols: GridColumn[], lsCol: LSColumn): GridColumn | null {
  const baseGridColumn = loadedCols.find(
    col => col.dataField === DynamicColumnCreator.getBaseFieldName(lsCol.dataField!) && col.userData?.baseMultiField
  );

  const currColumn = loadedCols.find(col => col.dataField === lsCol.dataField);

  if (!baseGridColumn || lsCol.userCreated) {
    return null;
  }

  return {
    ...baseGridColumn,
    ...lsCol,
    //@ts-ignore
    ID: lsCol.dataField,
    allowSorting: false,
    // @ts-ignore
    name: lsCol.dataField,
    showInColumnChooser: true,
    calculateCellValue: createDynamicCalculateCellValue(baseGridColumn.dataField!, lsCol.dataField!),
    setCellValue: createDynamicSetCellValue(baseGridColumn.dataField!, lsCol.dataField!),
    userData: {
      ...baseGridColumn.userData,
      isDynamicColumn: true,
      userCreated: lsCol.userCreated,
      baseMultiField: !!currColumn?.userData.baseMultiField
    }
  };
}

export default abstract class StateManager {
  static getColumns(viewSource: SysFormFields[], gridColumns: GridColumn[]) {
    function reduceCols(args: SaveArgs) {
      return [saveGroup, saveSystemCol, saveCol].reduce((col: LSColumn | void, fn) => col || fn(args), undefined);
    }

    return gridColumns.reduce((res: LSColumn[], column) => {
      const col = reduceCols({
        column,
        viewSource
      });
      if (col && !col.userCreated) res.push(col);
      return res;
    }, []);
  }

  static save(viewSource: SysFormFields[], lsName: string, gridColumns: GridColumn[]) {
    return StateManager.storageColumnsAsync(lsName, StateManager.getColumns(viewSource, gridColumns));
  }

  static getAvailableColumnOptions(): Array<keyof GridColumn> {
    return [
      'visibleIndex',
      'fixed',
      'fixedPosition',
      'sortIndex',
      'sortOrder',
      'visible',
      'isVisibleOnEditDockPanel',
      'width',
      'allowReordering',
      'required',
      'isCustomizable',
      'isReadOnly'
    ];
  }

  static async reset(lsKey: string) {
    let lsTableSettings = await StateManager.storageColumnsAsync(lsKey);

    await StateManager.storageColumnsAsync(
      lsKey,
      lsTableSettings.map(lsCol => {
        delete lsCol['width'];
        delete lsCol['fixed'];
        delete lsCol['fixedPosition'];
        delete lsCol['sortOrder'];
        delete lsCol['sortIndex'];
        delete lsCol['visibleIndex'];
        delete lsCol['visible'];

        return lsCol;
      })
    );
  }

  static async storageColumnsAsync(lsKey: string, storageColumns?: LSColumn[]) {
    if (storageColumns) {
      await TableDB.openTransaction('rw', () => {
        return TableDB.putTable({id: lsKey, columns: minimizeColumns(storageColumns)});
      });
      return [];
    }

    const table = await TableDB.getTable(lsKey);
    return maximizeColumns(table?.columns || []);
  }

  static async storageColumnsByFormKeys(formKeys: string[], userID: number): Promise<Record<string, LSColumn[]>> {
    const tableIds = formKeys.map(formKey => {
      const {formID, parentFormId} = splitFormKey(formKey);
      return createTableID(formID, parentFormId, userID);
    });

    return TableDB.getTablesById(tableIds).then(storageColumns =>
      storageColumns.reduce(
        (result, table) => {

          result[tableIdToFormKey(table.id!, userID)] = maximizeColumns(table.columns || []);
          return result;
        },
        {} as Record<string, LSColumn[]>
      )
    );
  }

  static applyStorageSettingsToColumns(lsColumns: TVLocalStorageColumn[], gridColumns: GridColumn[]) {
    const loadGroups = (lsCols: LSColumn[]) => {
      return lsCols.filter(lsCol => isDefined(lsCol.groupID)).map(col => transformStorageColumnToGrid(col));
    };

    const loadDynCols = (lsCols: LSColumn[]) => {
      return lsCols
        .filter(lsCol => lsCol.isDynamicColumn)
        .map(col => loadDynamicColumn(gridColumns, col))
        .filter(col => !!col);
    };

    const loadCols = (lsCols: LSColumn[]) => {
      const props = StateManager.getAvailableColumnOptions().filter(item => {
        return item !== 'isCustomizable' && item !== 'isReadOnly';
      });

      return lsCols.forEach(lsCol => {
        const {dataField, isDynamicColumn, isCombinedColumn} = lsCol;
        let column = gridColumns.find(col => col.dataField === dataField && dataField && !isDynamicColumn);

        if (!column) {
          return;
        }

        let localProps = [...props];

        if (!column.allowSorting) {
          localProps = localProps.filter(item => item !== 'sortOrder');
        }

        if (column?.userData?.fieldChildren || isCombinedColumn) {
          localProps = localProps.filter(item => !(item === 'fixed' || item === 'fixedPosition'));
        }

        localProps.forEach(name => {
          if (name in lsCol) {
            // @ts-ignore
            column[name] = lsCol[name];
          }
        });
      });
    };

    // @ts-ignore
    gridColumns.push(...loadGroups(lsColumns));
    // @ts-ignore
    gridColumns.push(...loadDynCols(lsColumns));
    loadCols(lsColumns);
    return gridColumns;
  }
}

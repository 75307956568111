import {AbstractStateStorage, StorageStringified} from './AbstractStateStorage';
import {createTableID} from '../storageKeys';
import {isEmptyValue} from '../../services/SecondaryMethods/typeUtils';
import parseJSONWithTranslation from '../parseJSONWithTranslation';
import {minimizeColumns} from '../storageConverter/convertColumns';
import {TVLocalStorageColumn as LSColumn} from '../../components/TableCoreOld/TableView';
import TableDB from '../../indexedDB/TableDB';
import {eraseUserIdFromItemKey} from '../../services/SecondaryMethods/getFormKey';

export default class TableStateStorage extends AbstractStateStorage {
  private readonly _formKey: string;

  constructor({userID, formKey}: {userID?: number; formKey: string}) {
    super({userID: userID!});
    this._formKey = formKey;
  }

  protected calcKey(userID: number, formKey: string): string {
    const [formID, parentFormId] = formKey.split('/');
    return createTableID(formID, parentFormId, userID);
  }

  get lsName(): string {
    return this.calcKey(this._userID, this._formKey);
  }

  static lsName(formKey: string, userID?: number) {
    return new TableStateStorage({formKey, userID}).lsName;
  }

  /**
   * Експортує налаштування таблиць.
   * Також використовується при ініціалізації застосунку для конвертації зі старої версії (localStorage) в нову (ДБ).
   * @see rewriteUserStorage
   */
  async exportAll(): Promise<StorageStringified> {
    //вичитуємо з localStorage налаштування
    const localStorageColumns = await super.exportAll();
    // якщо є локальні налаштування повертаємо їх, якщо ні, то шукаємо в БД
    if (localStorageColumns.length) return localStorageColumns;

    const tables = await TableDB.getAllTables(this._userID!);
    return tables.map(({id: tableId, columns}) => {
      return [eraseUserIdFromItemKey(tableId!, this._userID)!, JSON.stringify(columns)];
    });
  }

  /**
   * Імпортує налаштування таблиць
   * Також використовується при ініціалізації застосунку для конвертації зі старої версії (localStorage) в нову (ДБ).
   * Після того як записали значення в ДБ, видаляємо з localStorage
   * @see rewriteUserStorage
   */
  async importAll(states: StorageStringified = []): Promise<void> {
    return TableDB.openTransaction('rw', async () => {
      for (const [key, state] of states) {
        const parsedColumns: LSColumn[] = parseJSONWithTranslation(state);
        const lsKey = this._userID + key;
        if (!isEmptyValue(parsedColumns)) {
          TableDB.putTable({id: lsKey, columns: minimizeColumns(parsedColumns)});
        } else {
          TableDB.removeTable(lsKey);
        }
        localStorage.removeItem(lsKey);
      }
    });
  }
}

import {TableDBItem} from './types';
import {inDB} from './initIndexedDB';
import {type Transaction, type TransactionMode} from 'dexie';

export default abstract class TableDB {
  static putTable(table: TableDBItem) {
    return inDB.tablesStore.put(table);
  }

  static getTable(id: string) {
    return TableDB.openTransaction('r', () => inDB.tablesStore.get(id));
  }

  static getTablesById(tableIds: string[]): Promise<TableDBItem[]> {
    if (!tableIds.length) {
      return Promise.resolve([]);
    }

    return inDB.tablesStore.where('id').anyOf(tableIds).toArray()
  }

  static getAllTables(userID: number) {
    return inDB.tablesStore.where('id').startsWith(`${userID}-`).toArray();
  }

  static openTransaction<T = void>(mode: TransactionMode, callback: (tx?: Transaction) => Promise<T>): Promise<T> {
    return inDB.transaction(mode, inDB.tablesStore, callback);
  }

  static removeTable(tableId: string) {
    return inDB.tablesStore.delete(tableId);
  }

  static clear() {
    return inDB.tablesStore.clear();
  }
}

import {parseCustomParams} from 'components/newReactFormFields/DateControl/utils';
import {DX_TYPES, FILTER_OPERATIONS} from 'services/interfaces/global-interfaces';
import {SysForm, SysFormFields, SysFormFilterFields} from 'services/interfaces/sysObjects';
import {fields, system} from 'services/objects';
import {D5Error} from 'services/SecondaryMethods/errors';
import {isArray, isEmptyObject, isEmptyValue, isObject, isUndefined} from 'services/SecondaryMethods/typeUtils';
import {getFieldByObject} from 'utilsOld/datasource/utils';
import FilterOperationDataSource from 'utilsOld/FilterOperationDataSource';
import {getFilterSignByName} from 'utilsOld/helpers';
import {FilterField, SysFormFieldCollection} from 'utilsOld/systemObjects';
import BaseField from 'utilsOld/systemObjects/BaseField';
import DateSelectMode, {DATE_MODE} from 'utilsOld/systemObjects/DateSelectMode';
import FormField from 'utilsOld/systemObjects/FormField';
import SysFormFilterFieldCollection from 'utilsOld/systemObjects/SysFormFilterFieldCollection';
import {formatDateToAPI} from '../../../utilsOld/valueCasters';
import {FilterStateObject} from '../../filters/types';

const {
  notequal,
  equal,
  less,
  greater,
  greaterorequal,
  lessorequal,
  contains,
  isanyof,
  isnotanyof,
  isblank,
  isnotblank,
  startwith,
  bywords,
  between
} = FILTER_OPERATIONS;

const setToStartOfDay = (date: string) => date + 'T00:00:00';
const setToEndOfDay = (date: string) => date + 'T23:59:59';

export function operationFactory(componentOperation: FILTER_OPERATIONS) {
  switch (componentOperation) {
    case contains:
      return 'like';
    case notequal:
      return '!=';
    case isblank:
    case isanyof:
      return '=';
    case isnotblank:
    case isnotanyof:
      return '!=';
    case startwith:
    case bywords:
      return '%~';
    default:
      return componentOperation;
  }
}

export interface IFormatFilterToAPI {
  storeFilters: Record<string, FilterStateObject>;
  sysForm: SysForm;
}

const makeApiFilterValue = (
  operation: FILTER_OPERATIONS,
  filterValue: string[] | null = null,
  options: {[key: string]: any}
) =>
  options &&
  apiFilterValue({
    dxType: options.dxType,
    filterValue,
    operation,
    isDataFieldTimeAllowed: options.isDataFieldTimeAllowed,
    dateSelectMode: options.dateSelectMode
  });

export const formatToAPI = ({storeFilters, sysForm}: IFormatFilterToAPI) => {
  let outFilter: {[key: string]: any} = {};
  const {isblank, isnotblank, equal} = FILTER_OPERATIONS;

  const isValidValue = (val: any) => {
    const isEmptyStr = val === '';
    const isEmptyObj = isObject(val) && isEmptyObject(val);
    const isEmptyArr = Array.isArray(val) && val.every(v => v == null);
    return !isEmptyStr && !isUndefined(val) && !isEmptyObj && !isEmptyArr;
  };

  for (const filter in storeFilters) {
    const {operation, value, requestField, hasIsBlank = false} = storeFilters[filter];

    let options = getFilterOptions({
      requestField,
      formFilters: sysForm.Sys_FormFilterFields!,
      viewSource: sysForm.Sys_FormFields!
    });

    const currFilter = sysForm.Sys_FormFilterFields?.find(
      field => (field[fields.ObjectFieldID_Name] || field[fields.Name]) === requestField
    );
    const {EndValueField = undefined} = currFilter?.DisplayCustomParam
      ? parseCustomParams(currFilter?.DisplayCustomParam, currFilter?.DisplayCustomParam)
      : {};

    if (!options) continue;

    if (EndValueField && value) {
      try {
        const [value1, value2] = isArray(value) ? value : [value, storeFilters[EndValueField].value];
        outFilter[requestField] = makeApiFilterValue(equal, value1, options);
        outFilter[EndValueField] = makeApiFilterValue(equal, value2, options);
      } catch {
        D5Error.log('E2022');
      }
      continue;
    }

    if (isValidValue(value) || [isnotblank, isblank].includes(operation)) {
      outFilter[requestField] = makeApiFilterValue(operation, value, options);
    }

    if (hasIsBlank) {
      let filterValue = outFilter[requestField];
      outFilter[requestField] = !isEmptyValue(filterValue) ? [filterValue, {'=': null}] : [{'=': null}];
    }

    if (isUndefined(outFilter[requestField])) delete outFilter[requestField];
  }

  return outFilter;
};

interface IGetFilterOptions {
  requestField: string;
  formFilters: SysFormFilterFields[];
  viewSource: SysFormFields[];
}

function getFilterOptions({requestField, formFilters, viewSource}: IGetFilterOptions):
  | {
      dxType: DX_TYPES;
      isDataFieldTimeAllowed: boolean;
      dateSelectMode: DATE_MODE;
      name: string;
    }
  | undefined {
  //Для всех левых фильтров(над таблице и модальная форма)
  const filterField =
    formFilters &&
    formFilters.find(fFilter => [fFilter[fields.ObjectFieldID_Name], fFilter.Name].includes(requestField));

  if (filterField) {
    const filterInst = new FilterField(filterField);

    return {
      dxType: filterInst.getFieldType() as DX_TYPES,
      isDataFieldTimeAllowed: filterInst.isDataFieldTimeAllowed,
      dateSelectMode: filterInst.dateSelectMode,
      name: filterInst.name
    };
  } else {
    const field = getFieldByObject(viewSource, requestField);
    if (field) {
      let formField = new FormField(field);

      return {
        dxType: formField.getFieldType() as DX_TYPES,
        isDataFieldTimeAllowed: formField.isDataFieldTimeAllowed,
        dateSelectMode: formField.dateSelectMode,
        name: formField.name
      };
    }
  }
}

export function formatFilterDateArray(value: Date[], isTimeAllowed?: boolean) {
  const withTime = isTimeAllowed;
  let result = value.map((date: any) => formatDateToAPI(new Date(date), withTime));
  return result;
}

type NotequalConditionType = {operation: FILTER_OPERATIONS; value: string};
interface NormalizeDateFilterOperationResult {
  operation: FILTER_OPERATIONS;
  value: string | string[];
  notequalConditions?: NotequalConditionType[];
}
function normalizeDateFilterOperation(
  operation: FILTER_OPERATIONS,
  filterValue: string
): NormalizeDateFilterOperationResult {
  switch (operation) {
    case equal:
      return {operation: between, value: [setToStartOfDay(filterValue), setToEndOfDay(filterValue)]};
    case notequal:
      return {
        operation: notequal,
        value: '',
        notequalConditions: [
          {operation: less, value: setToStartOfDay(filterValue)},
          {operation: greater, value: setToEndOfDay(filterValue)}
        ]
      };
    case less:
    case lessorequal:
      return {operation, value: setToStartOfDay(filterValue)};

    case greater:
    case greaterorequal:
      return {operation, value: setToEndOfDay(filterValue)};
    default:
      return {operation, value: filterValue};
  }
}

interface NormalizeDateValueParams {
  operation: FILTER_OPERATIONS;
  value: any;
  isDataFieldTimeAllowed: boolean;
  dateSelectMode: DATE_MODE;
}

interface NormalizeDateValueResult {
  operation: FILTER_OPERATIONS;
  value: string | string[];
  notequalConditions?: NotequalConditionType[];
}

function normalizeDateValue({
  operation,
  value,
  isDataFieldTimeAllowed,
  dateSelectMode
}: NormalizeDateValueParams): NormalizeDateValueResult {
  const isWeekOrMonthDateSelectMode = DateSelectMode.isWeekOrMonth(dateSelectMode);
  const withTime = !isWeekOrMonthDateSelectMode && isDataFieldTimeAllowed;

  if (Array.isArray(value)) {
    value = formatFilterDateArray(value, withTime);

    if (isWeekOrMonthDateSelectMode) {
      const [startDate, endDate] = value;
      value = isDataFieldTimeAllowed
        ? [setToStartOfDay(startDate), setToEndOfDay(endDate)]
        : setToStartOfDay(startDate);
      operation = isDataFieldTimeAllowed ? between : equal;
    } else {
      operation = between;
    }
  } else {
    value = formatDateToAPI(new Date(value));

    if (isDataFieldTimeAllowed) {
      const {
        operation: newOperation,
        value: newValue,
        notequalConditions
      } = normalizeDateFilterOperation(operation, value);
      if (notequalConditions) {
        return {operation: newOperation, value: newValue, notequalConditions};
      }
      value = newValue;
      operation = newOperation;
    } else {
      value = setToStartOfDay(value);
    }
  }

  return {operation, value};
}

function apiFilterValue({
  operation,
  filterValue,
  dxType,
  isDataFieldTimeAllowed,
  dateSelectMode
}: {
  operation: FILTER_OPERATIONS;
  filterValue: any;
  dxType: DX_TYPES;
  isDataFieldTimeAllowed: boolean;
  dateSelectMode: DATE_MODE;
}) {
  const {dxCheckBox, dxSwitch, dxDateBox} = system.DX_CONTROLS;

  let value = filterValue;
  const isBlankOperation = operation === isnotblank || operation === isblank;

  if (!isBlankOperation && (value == null || value === '')) return;

  if (dxType === dxCheckBox || dxType === dxSwitch) {
    // нем дозволяє створювати фільтр на основі массива з двома і більше значеннями
    if (Array.isArray(value) && value.length > 1) {
      throw D5Error.create('E1036', [dxType]);
    }
    operation = equal;
    value = Number(value);
  }

  if (dxType === dxDateBox) {
    const {
      value: newValue,
      operation: newOperation,
      notequalConditions
    } = normalizeDateValue({
      value,
      operation,
      isDataFieldTimeAllowed,
      dateSelectMode
    });
    value = newValue;
    operation = newOperation;

    if (notequalConditions) {
      return notequalConditions.map(({operation, value}) => ({
        [operationFactory(operation)]: value
      }));
    }
  }

  if (isBlankOperation) {
    value = null;
  }

  if (value && operation === startwith) {
    value = value + '%';
  }

  if (value && operation === bywords) {
    value = '%' + value.trim().replace(/\s+/g, '%') + '%';
  }

  function createFilterValueObject() {
    return {[operationFactory(operation)]: value};
  }

  return createFilterValueObject();
}

export function parseFilterParam(query: string) {
  try {
    return JSON.parse(query);
  } catch (e) {
    D5Error.log('E1002', [query, e]);

    return {};
  }
}

interface ApiValueToAppParams {
  operation: FILTER_OPERATIONS;
  value: any;
  field: FilterField | FormField;
  filterFields: any[];
  hasIsBlank: boolean;
}

function apiValueToApp({operation, value, field, filterFields, hasIsBlank}: ApiValueToAppParams) {
  // якщо hasIsBlank і operation bywords або startwith то з value потрібно прибрати символ `%` який додається
  if (operation === isnotblank || operation === isblank || (hasIsBlank && ![bywords, startwith].includes(operation))) {
    return value ?? undefined;
  }

  if (operation === equal) {
    const {EndValueField = undefined} = parseCustomParams(field.displayCustomParam, field.objectFieldIDName);
    if (EndValueField) {
      const [, body] = filterFields.find(([key]) => key === EndValueField) || [];
      const [[, endValue]]: any = Object.entries(body);
      return [value, endValue];
    }

    // Проверка даты на одинаковое значение.
    // Если значение одинаковые в не зависимости от времени, так как оно опускается
    // (у фильтров не может быть время, только дата), то ставить equal('=')
    if (field.isDate() && !isEmptyValue(value) && isArray(value)) {
      const [val1, val2] = value;
      if (val1.split('T')?.[0] === val2.split('T')?.[0]) {
        return val1.split('T')?.[0];
      }
    }
  }

  if (operation === bywords && isByWordFilter(value)) {
    return convertDataToUrl(value.substring(1, value.length - 1));
  }

  if (operation === startwith && isStartWithFilter(value)) {
    return value.substring(0, value.length - 1);
  }
  if (operation === equal && (field.isLookup() || field.isEnum()) && !field.isMultiSelect) {
    if (isArray(value)) return value[0];
  }

  return value ?? undefined;
}

export function isByWordFilter(str: string) {
  return str.startsWith('%') && str.endsWith('%');
}

export function isStartWithFilter(str: string) {
  return str.endsWith('%');
}

export function getFirstAvailableOperation(fieldInst: FilterField) {
  const availableOperations = FilterOperationDataSource.getDataSource(fieldInst);

  const firstAvailableOperation = availableOperations
    .map(availableOpts => availableOpts.operation)
    .find(operation => operation !== isblank);

  return getFilterSignByName(firstAvailableOperation);
}

function apiOperationToApp(operation: string, value: any, fieldInst: FilterField): FILTER_OPERATIONS {
  if (operation === 'like') return contains;
  if (operation === '!=') {
    if (fieldInst.isFieldWithArray() && isArray(value)) {
      return isnotanyof;
    }
    if (value === null) {
      return isnotblank;
    }
    return notequal;
  }

  if (operation === '=') {
    if (fieldInst.isLookup() || fieldInst.isEnum()) {
      if (fieldInst.isMultiSelect) {
        return isanyof;
      }
      if (isArray(value) && value.length) {
        return equal;
      }
    }
    if (!fieldInst.isLookup() && (fieldInst.isNumber() || fieldInst.isText())) {
      if (value === null) {
        return getFirstAvailableOperation(fieldInst);
      }
      if (isArray(value) && value.length) return isanyof;
    }
    return equal;
  }

  if (operation === between) {
    // Проверка даты на одинаковое значение.
    // Если значение одинаковые в не зависимости от времени, так как оно опускается
    // (у фильтров не может быть время, только дата), то ставить equal('=')
    if (fieldInst.isDate() && !isEmptyValue(value) && isArray(value)) {
      const [val1, val2] = value;
      if (val1.split('T')?.[0] === val2.split('T')?.[0]) {
        return equal;
      }
    }
    return between;
  }

  if (operation === '%~') {
    let str = '' + value;
    if (isByWordFilter(str)) return bywords;
    if (isStartWithFilter(str)) return startwith;
    return between;
  }

  return operation as FILTER_OPERATIONS;
}

export const formatFilterToStore = (apiFilter: Record<string, any>, sysForm: SysForm) => {
  const filterEntries = Object.entries(apiFilter);

  const storeFilterItem = (operation: FILTER_OPERATIONS, value: any, fieldInst: any, hasIsBlank: boolean = false) => {
    const getFilterRequestField = (fld: BaseField<any>) => fld.objectFieldIDName || fld.name;

    const configuredFilterField: FilterField | undefined = new SysFormFilterFieldCollection(
      sysForm.Sys_FormFilterFields || []
    )?.findInstance(fld => {
      return fld.name === getFilterRequestField(fieldInst as BaseField<any>);
    });

    return {
      operation,
      value: configuredFilterField
        ? apiValueToApp({
            operation: operation,
            value: value,
            field: fieldInst,
            filterFields: filterEntries,
            hasIsBlank: hasIsBlank
          })
        : undefined,
      isTable: fieldInst instanceof FormField,
      requestField: getFilterRequestField(fieldInst as BaseField<any>),
      displayValue: undefined,
      hasIsBlank
    };
  };

  const storeFilterByEntry = (apiFilterEntry: Record<string, any>, fieldInst: any, hasIsBlank: boolean = false) => {
    let [[bodyOperation, value]]: any = Object.entries(apiFilterEntry);
    let operation = apiOperationToApp(bodyOperation, value, fieldInst);
    return storeFilterItem(operation, value, fieldInst, hasIsBlank);
  };

  return filterEntries.reduce((acc: Record<string, any>, [key, filterBody]) => {
    if (isObject(filterBody) && isEmptyObject(filterBody)) {
      return acc;
    }

    const getBaseField = (sysForm: SysForm, key: string) => {
      const result: FilterField | FormField | undefined = new SysFormFilterFieldCollection(
        sysForm.Sys_FormFilterFields || []
      ).findInstance(fld => {
        return fld.objectFieldIDName === key || fld.name === key;
      });

      if (result) {
        return result;
      }

      return new SysFormFieldCollection(sysForm.Sys_FormFields || []).findInstance(fld => {
        return fld.objectFieldIDName === key;
      });
    };

    let fieldInst = getBaseField(sysForm, key);
    if (!fieldInst) return acc;

    // если значение фильтра НЕ является объектом, то это сокращенная запись
    // операции '=' (например, {Name: 'Roma'})
    if (isObject(filterBody)) {
      acc[key] = storeFilterByEntry(filterBody, fieldInst);
      return acc;
    }

    if (isArray(filterBody)) {
      const blankIndex = filterBody.findIndex((expr: any) => expr.hasOwnProperty('=') && expr['='] == null);
      if (blankIndex === -1) return acc;
      let [blankExpr] = filterBody.splice(blankIndex, 1);

      acc[key] = storeFilterByEntry(filterBody[0] ?? blankExpr, fieldInst, !!blankExpr);

      return acc;
    }

    acc[key] = storeFilterItem(FILTER_OPERATIONS.equal, filterBody, fieldInst);

    return acc;
  }, {});
};

export const convertUrlToData = (url: string) => '%' + url.replace(/ /g, '%') + '%';

export const convertDataToUrl = (data: string) => data.replace(/%/g, ' ');

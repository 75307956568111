import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DaysOff, SchedulerResource, SchedulerView} from 'components/widgets/Scheduler/types';
import {createAsyncThunk} from '../reduxUtils';
import {fetchData, getDataSource} from 'components/forms/scheduler/utils/getSchedulerDataSource';
import {isDefined, isEmptyValue} from '../SecondaryMethods/typeUtils';
import {loaderEnd, loaderStart} from '../loading/actions';
import {SysFormWrapper} from '../../utilsOld/systemObjects';
import {makeEventQueueSelector} from '../currentForms/selectors';
import DataSource from 'devextreme/data/data_source';
import {makeFilterSelector} from '../filters/selectors';
import {formatToAPI} from '../SecondaryMethods/filterUtils';

export type SchedulerState = {
  views: SchedulerView[];
  daysOff: DaysOff[];
  resources: SchedulerResource[];
  startDayHour: number;
  endDayHour: number;
};

const createInitialState = (): SchedulerState => {
  return {
    views: [],
    daysOff: [],
    resources: [],
    startDayHour: 0,
    endDayHour: 24
  };
};

const initialState: Record<string, SchedulerState> = {};

const {actions, reducer: schedulerReducer} = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    setViews(
      state,
      {
        payload: {formKey, views}
      }: PayloadAction<{
        formKey: string;
        views: SchedulerState['views'];
      }>
    ) {
      state[formKey] = state[formKey] ?? createInitialState();
      state[formKey].views = views;
    },
    setResources(
      state,
      {
        payload: {formKey, resources}
      }: PayloadAction<{
        formKey: string;
        resources: SchedulerState['resources'];
      }>
    ) {
      state[formKey].resources = resources;
    },
    setDaysOff(
      state,
      {
        payload: {formKey, daysOff}
      }: PayloadAction<{
        formKey: string;
        daysOff: SchedulerState['daysOff'];
      }>
    ) {
      state[formKey].daysOff = daysOff;
    },
    setDayHours(
      state,
      {
        payload: {formKey, endDayHour, startDayHour}
      }: PayloadAction<{
        formKey: string;
        startDayHour?: SchedulerState['startDayHour'];
        endDayHour?: SchedulerState['endDayHour'];
      }>
    ) {
      if (isDefined(startDayHour)) {
        state[formKey].startDayHour = startDayHour!;
      }

      if (isDefined(endDayHour)) {
        state[formKey].endDayHour = endDayHour!;
      }
    },
    setViewGroupWidth(
      state,
      {
        payload: {formKey, name, width}
      }: PayloadAction<{
        formKey: string;
        name: SchedulerView['name'];
        width: SchedulerView['__groupColumnWidth'];
      }>
    ) {
      const view = state[formKey].views.find(view => view.name === name);
      if (view) {
        view.__groupColumnWidth = width;
      }
    },
    updateSchedulers(
      state,
      {
        payload
      }: PayloadAction<
        {
          formKey: string;
          resources?: SchedulerState['resources'];
          startDayHour?: SchedulerState['startDayHour'];
          endDayHour?: SchedulerState['endDayHour'];
        }[]
      >
    ) {
      payload.forEach(({formKey, ...data}) => {
        if (!state[formKey]) return;

        if (data.resources) {
          state[formKey].resources = data.resources;
        }

        if (isDefined(data.startDayHour)) {
          state[formKey].startDayHour = data.startDayHour!;
        }

        if (isDefined(data.endDayHour)) {
          state[formKey].endDayHour = data.endDayHour!;
        }
      });
    }
  }
});

/**
 * Оновлює датасорси в ресурсах. Решту властивостей залишаються, в тому числі фільтр.
 * Може змінювати кількість записів в dataSource.
 */
export const fetchSchedulerResources = createAsyncThunk<SchedulerResource[], string>(
  'scheduler/fetchSchedulerResources',
  (formKey, {getState}) => {
    const storedResources = getState().scheduler[formKey]?.resources ?? [];
    const loadPromises = storedResources.map(resource => {
      //Якщо немає objectName до ресурс був доданий в користувацькому скрипті
      if (resource.objectName) {
        return fetchData({
          dictObj: resource.objectName,
          columns: [resource.colorExpr, resource.displayExpr, resource.fieldExpr, resource.valueExpr].filter(
            res => !isEmptyValue(res)
          ),
          filter: resource.filter ?? undefined,
          sorts: resource.sortExpr ? [resource.sortExpr] : []
        });
      }
      return Promise.resolve({
        ...resource,
        dataSource: resource.dataSource || []
      });
    });
    return Promise.all(loadPromises).then(resourcesData => {
      return storedResources.map((res, index) => {
        if (!res.objectName) {
          return res;
        }
        return {
          ...res,
          //Після того як отримали дані кешуємо фільтр, щоб коли його змінять
          //Можна було порівняти його з новим що в полі filter
          filterCached: res.filter,
          dataSource: resourcesData[index] ?? []
        };
      });
    });
  }
);

export const createAppointmentsDatasource = createAsyncThunk<DataSource, {formKey: string; sysForm: SysFormWrapper}>(
  'scheduler/createAppointmentsDatasource',
  async ({formKey, sysForm}, {getState, dispatch}) => {
    const storedResources = getState().scheduler[formKey]?.resources ?? [];
    const eventQueue = makeEventQueueSelector()(getState(), sysForm.name);
    const storeFilters = makeFilterSelector()(getState(), formKey);
    const {
      SCHEDULER_DescriptionField: descriptionExpr,
      SCHEDULER_DisabledField: disabledExpr,
      SCHEDULER_EndDateField: endDateExpr,
      SCHEDULER_StartDateField: startDateExpr,
      SCHEDULER_TextField: textExpr,
      object: mainObjectName
    } = sysForm;

    const getAppointmentsColumns = () => {
      const resourcesColumnsNames = (storedResources || []).map(item => item.fieldExpr).filter(r => r);
      return [descriptionExpr, disabledExpr, endDateExpr, startDateExpr, textExpr, ...resourcesColumnsNames];
    };

    dispatch(loaderStart({formKey}));

    const dataSource = getDataSource({
      dictObj: mainObjectName,
      columns: getAppointmentsColumns(),
      formID: sysForm.name,
      formKey,
      eventQueue
    });

    dataSource.apiFilter = formatToAPI({
      storeFilters,
      sysForm: sysForm.asRaw()
    });

    dispatch(loaderEnd({formKey}));
    return dataSource;
  }
);

export const {setDayHours, updateSchedulers, setDaysOff, setResources, setViews, setViewGroupWidth} = actions;

export default schedulerReducer;

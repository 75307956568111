export interface AvailableStyles {
  background: string;
  backgroundColor: string;
  fontSize: string;
  lineHeight: string;
  color: string;
  fontWeight: string;
}

export const getAvailableStyles = (): AvailableStyles => ({
  background: '',
  backgroundColor: '',
  fontSize: '',
  lineHeight: '',
  color: '',
  fontWeight: ''
})

export const stylesWrapper = <T extends {style: Record<string, any>}>(el: T) => {
  const _styles = getAvailableStyles();

  return new Proxy(_styles, {
    set(target: AvailableStyles, p: string, value: any, receiver: any): boolean {
      if (!_styles.hasOwnProperty(p)) {
        return true;
      }
      el.style[p as any] = value;
      return Reflect.set(target, p, value, receiver);
    }
  });
};

export const stylesRowWrapper = (
  elements: HTMLElement[],
  rowStyles: Record<string, any> = {}
) => {
  const _styles = getAvailableStyles();
  return new Proxy(_styles, {
    set(target: AvailableStyles, p: string, value: any, receiver: any): boolean {
      if (!_styles.hasOwnProperty(p)) {
        return true;
      }

      rowStyles[p as any] = value;

      elements.forEach(el => {
        if (!el) return;

        const cols = [...el.children] as HTMLElement[];
        for (let col of cols) {
          col.style[p as any] = value;
        }
      });
      return Reflect.set(target, p, value, receiver);
    }
  });
};

import {runFormScriptSync} from 'middlewares/userScript/utils';
import {getEvent} from 'middlewares/userScript/utils/getEvent';
import {getRow} from '../elems/listForm/D5Row';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import {USER_EVENT_TYPE} from '../../../services/interfaces/global-interfaces';
import {getAvailableStyles} from '../elems/stylesWrapper';
import {isEmptyValue} from '../../../services/SecondaryMethods/typeUtils';

export const onCustomizeCell = ({
  sysForm,
  rowData,
  rowIndex,
  isSelected,
  isExpanded,
  dynamicColumnName,
  name,
  tdHtmlElement
}) => {
  const eventType = USER_EVENT_TYPE.ON_CUSTOMIZE_CELL;
  const eventName = getEvent({sysForm, name, eventType});
  const customStyles = getAvailableStyles();
  const wrapper = new SysFormWrapper(sysForm);

  runFormScriptSync({
    form: {name: wrapper.name},
    core: null,
    eventName,
    args: [
      {
        row: getRow(rowData, wrapper.keyField),
        style: customStyles,
        rowIndex,
        isSelected,
        isExpanded,
        tdHtmlElement,
        dynamicColumnName
      }
    ]
  });

  return Object.fromEntries(Object.entries(customStyles).filter(([_, v]) => !isEmptyValue(v)));
};

import {HELP_ARTICLE_FORM} from '../objects';

export function splitFormKey(formKey: string) {
  let [formID, parentFormId = null] = formKey.split('/');
  return {formID, parentFormId};
}

export function eraseUserIdFromItemKey(itemKey: string, userID: number | string) {
  return itemKey.slice(userID.toString().length);
}

export function getFormKey(formID: string, parentID: string | null = null) {
  return formID && `${formID}` + (parentID == null ? '' : `/${parentID}`);
}

export function getHelpArticleFormKey(formKey: string, guid: string) {
  return `${formKey}-${HELP_ARTICLE_FORM}-${guid}`;
}

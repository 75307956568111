import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {defaultResult} from '../../utilsOld/datasource/utils';

type DataSourceCacheStore = {
  data: Record<string, any>[];
  summary: Record<string, any>;
  totalCount: number;
  pagesPredicted: number;
};

type Data = DataSourceCacheStore['data'];
type Summary = DataSourceCacheStore['summary'];

type DataSourceCacheStoreState = Record<string, DataSourceCacheStore>;

const initialState: DataSourceCacheStoreState = {};

const slice = createSlice({
  name: 'dataSourceCacheStore',
  initialState,
  reducers: {
    initDataSourceCacheStore(state, action: PayloadAction<string>) {
      if (state[action.payload]) return;
      state[action.payload] = defaultResult();
    },
    clearDataSourceCacheStore(state, action: PayloadAction<string>) {
      delete state[action.payload];
    },
    appendToDataSourceCacheStore(state, action: PayloadAction<{formKey: string; data: Data}>) {
      const {formKey, data} = action.payload;
      if (!state[formKey]) return;
      state[formKey].data.push(...data);
    },
    insertToDataSourceCacheStore(state, action: PayloadAction<{formKey: string; data: Data}>) {
      const {formKey, data} = action.payload;
      if (!state[formKey]) return;
      state[formKey].data.unshift(...data);
    },
    replaceDataInDataSourceCacheStore(state, action: PayloadAction<{formKey: string; data: Data}>) {
      const {formKey, data} = action.payload;
      if (!state[formKey]) return;
      state[formKey].data = data;
    },
    setSummaryInDataSourceCacheStore(state, action: PayloadAction<{formKey: string; summary: Summary}>) {
      const {formKey, summary} = action.payload;
      if (!state[formKey]) return;
      state[formKey].summary = summary;
    }
  }
});

export const dataSourceCacheStoreActions = slice.actions;

export default slice.reducer;

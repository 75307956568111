import {isEmptyValue, isFunction} from '../../services/SecondaryMethods/typeUtils';

type Key = string;
type StringifiedValue = string;
export type StorageStringified = [Key, StringifiedValue][];

export interface StateStorage {
  exportAll(): Promise<StorageStringified>;

  importAll(data: StorageStringified): Promise<void>;
}

export abstract class AbstractStateStorage implements StateStorage {
  protected _userID: number;
  protected _storage: Storage;

  protected constructor({userID, storage = localStorage}: {userID: number; storage?: Storage}) {
    this._userID = userID;
    this._storage = storage;
  }

  protected abstract calcKey(userID: number, formKey: string): string;

  protected validateKey(key: string, itemKey: string) {
    return itemKey.startsWith(key);
  }

  protected exportAllFromStorage(storage: Storage, validateKey?: typeof this.validateKey): StorageStringified {
    if (!this._userID) throw new TypeError('UserId is not specified');

    const key = this.calcKey(this._userID, '');
    let result: StorageStringified = [];
    const validationCallback = validateKey ?? this.validateKey;

    for (let i = 0; i < storage.length; i++) {
      const itemKey = storage.key(i) ?? '';
      validationCallback(key, itemKey) &&
        result.push([itemKey.slice(this._userID.toString().length), storage.getItem(itemKey) ?? '{}']);
    }
    return result;
  }

  exportAll(): Promise<StorageStringified> {
    return Promise.resolve(this.exportAllFromStorage(this._storage));
  }

  importAll(states: StorageStringified = [], convertFunc?: (state: string) => string): Promise<void> {
    if (!this._userID) throw new TypeError('UserId is not specified');

    for (const [key, state] of states) {
      const updState = isFunction(convertFunc) ? convertFunc?.(state) : state;
      if (!isEmptyValue(JSON.parse(state))) {
        this._storage.setItem(this._userID + key, updState as string);
      } else {
        this._storage.removeItem(this._userID + key);
      }
    }

    return Promise.resolve();
  }
}

import {initDialog, showDialog, showPrompt} from './actions';
import {FIELD_EDITOR_TYPE, NOTIFICATION_TYPES} from '../interfaces/global-interfaces';
import {DialogButton} from '../../middlewares/userScript/elems/public-interfaces';
import {createReducer} from '@reduxjs/toolkit';

type PromptValue = any;

export interface DialogState {
  visible: boolean;
  type: NOTIFICATION_TYPES;
  title: string;
  text: string;
  buttons: DialogButton[];
  opacity: number;
  resolve: null | ((btn: DialogButton | PromptValue) => void);
  prompt?: {
    inputType: FIELD_EDITOR_TYPE;
    defaultValue: PromptValue;
    dataSource?: Record<number | string, number | string>[];
    isMultiSelect?: boolean;
    isDialogPrompt?: boolean;
  };
}

export const initialState: DialogState = {
  visible: false,
  type: NOTIFICATION_TYPES.Info,
  title: '',
  text: '',
  buttons: [],
  opacity: 85,
  resolve: null
};

export const dialogReducer = createReducer(initialState, builder => {
  builder
    .addCase(initDialog, (state, action) => ({...state, ...action.payload}))
    .addCase(showDialog.fulfilled, () => initialState)
    .addCase(showPrompt.fulfilled, () => initialState);
});

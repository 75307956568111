import DataSource from 'devextreme/data/data_source';
import {postWithPromise} from 'services/requests/baseRequests';
import {wapiUrl} from 'services/baseUrl';
import store from 'store';
import {fields} from 'services/objects';
import {isEmptyObject, isEmptyValue, isObject} from 'services/SecondaryMethods/typeUtils';
import {SearchModes} from 'components/newReactFormFields/types';
import ListSelectorDataSource from './datasource/ListSelectorDataSource';
import {checkServicePrefix, defaultSorts} from '../services/SecondaryMethods/constRequests';
import {FilterField, FormField} from './systemObjects';
import {LoadOptions} from 'devextreme/data';
import D5DataSource from './datasource/D5DataSource';
import {getUIVersionLS} from '../components/useUIVersion';

export const DEFAULT_LOOKUP_ROW_PER_PAGE = 100;
const minSearchLength = 2;

export const getLoadParams = (value: string) => {
  if (value.length && value.length < minSearchLength) return {};
  return {
    [SearchModes.CONTAINS]: {
      operation: 'like',
      value
    },
    [SearchModes.STARTS_WITH]: {
      operation: '%~',
      value: isEmptyValue(value) ? value : `${value}%`
    },
    [SearchModes.BY_WORDS]: {
      operation: '%~',
      value: isEmptyValue(value) ? value : `%${value.trim().replace(/ /g, '%')}%`
    }
  };
};
const resultPostWithPromise = async ({
  path,
  columns,
  filter,
  page,
  rowsPerPage,
  sorts,
  params
}: {
  path: string;
  columns: string[];
  filter: Record<string, any>;
  page: number;
  rowsPerPage: number;
  sorts: string[];
  params?: {Params: any};
}) => {
  const {response} = await postWithPromise({
    data: {
      Columns: columns,
      Filters: filter,
      Page: page || -1,
      RowsPerPage: rowsPerPage,
      Sorts: sorts,
      ...params
    },
    url: `${wapiUrl}/${path}/List`
  });
  return response;
};

type ReactSelectFieldDSParam = {
  dictObj?: string;
  dictKeyFld?: string;
  dictNameFld?: string;
  dictDisplayFld?: string;
  formID: string;
  formKey: string;
  name: string;
  sortValues?: string[];
  onInitFunction?: IOnInitFunction;
  colorSchemeFieldName?: string;
  stylingModeFieldName?: string;
  operationsParams?: Record<string, any>;
};

function reactSelectFieldDS(
  {
    dictObj,
    dictKeyFld,
    dictDisplayFld,
    formID,
    formKey,
    name,
    onInitFunction,
    sortValues,
    colorSchemeFieldName,
    stylingModeFieldName
  }: ReactSelectFieldDSParam,
  _filter = []
) {
  if (!(dictObj && dictKeyFld && dictDisplayFld)) {
    return;
  }
  // @ts-ignore
  const newDataSource: any = new DataSource({
    filter: _filter == null || !_filter.length ? null : _filter,
    paginate: true,
    customSearchValue: '',
    load: function (loadOptions) {
      const toggledDisplayExprSorts: string[] | undefined = [];
      if (newDataSource?.customSearchOperation) {
        loadOptions = {...loadOptions, searchOperation: newDataSource.customSearchOperation};
      }

      let {take, skip} = loadOptions;
      const page = getPageForRequest(skip!, take!);

      if (page > 1 && newDataSource?.customSearchValue) {
        loadOptions = {...loadOptions, searchValue: newDataSource?.customSearchValue};
      }
      if (newDataSource?.toggleDisplayExpr) {
        toggledDisplayExprSorts.push(dictDisplayFld);
      }
      newDataSource.customSearchValue = '';

      return getSelectFieldData({
        loadOptions,
        dictObj,
        dictKeyFld: dictKeyFld,
        dictNameFld: dictDisplayFld,
        colorSchemeFieldName,
        stylingModeFieldName,
        dictDisplayFld: newDataSource?.toggleDisplayExpr ? dictKeyFld : dictDisplayFld,
        formID,
        formKey,
        name,
        operationsParams: newDataSource?.operationsParams,
        sortValues: sortValues?.length ? sortValues : toggledDisplayExprSorts,
        onInitFunction
      });
    },
    insert: values => {
      return Promise.resolve(values);
    },
    byKey: async function (key: any) {
      if (Array.isArray(key) && !key.length) {
        return;
      }
      const columns = [dictKeyFld, dictDisplayFld];

      const {response} = await postWithPromise({
        data: {
          Columns: columns,
          Filters: {
            [dictKeyFld]: {'=': key}
          },
          Params: newDataSource?.operationsParams ? newDataSource?.operationsParams : undefined,
          Sorts: [dictDisplayFld]
        },
        url: `${wapiUrl}/${dictObj}/List`
      });

      //filter response with needed data
      let findRequestData = response[dictObj].filter((el: Record<string, any>) => el[dictKeyFld] === key);
      return findRequestData.length ? findRequestData : null;
    }
  });

  return newDataSource;
}

function reactSelectSearchFieldDS({
  dictObj,
  columns,
  servicePrefix,
  setSearchItemsLength
}: {
  dictObj: string;
  columns: string[];
  servicePrefix?: string;
  setSearchItemsLength: (len: number) => void;
}) {
  //@ts-ignore
  const newDataSource = new DataSource({
    pageSize: DEFAULT_LOOKUP_ROW_PER_PAGE,
    paginate: true,
    load: async function (loadOptions) {
      let prefix = checkServicePrefix(servicePrefix);

      //@ts-ignore
      if (newDataSource?.customSearchOperation) {
        //@ts-ignore
        loadOptions = {...loadOptions, searchOperation: newDataSource.customSearchOperation};
      }

      const result = await getSelectSearchedFieldData({
        loadOptions,
        dictObject: dictObj,
        columns,
        params: {...prefix}
      });

      setSearchItemsLength(result.length);

      return result;
    },
    insert: () => {
      return null;
    },
    byKey: function () {
      return Promise.resolve(null);
    }
  });

  return newDataSource;
}

/**
 * Хелпер для вызова аргумента-функции func единожды
 */
export const callOnce = (func: Function, context: any = null) => {
  let called = false;
  return function () {
    if (called) {
      return;
    }
    called = true;
    return func.apply(context, arguments);
  };
};

const createFieldDataSource = (
  field: FormField | FilterField,
  onInitFunction: IOnInitFunction,
  formKey: string
): {
  dataSource: D5DataSource | ListSelectorDataSource;
  displayExpr: string;
  valueExpr: string;
  dictObj: string;
} => {
  let dataSource: D5DataSource | ListSelectorDataSource = reactSelectFieldDS({
    dictObj: field.getLinkedName(),
    dictKeyFld: field.getKeyObjFieldName(),
    dictDisplayFld: field.displayField,
    colorSchemeFieldName: field.LookupTagColorSchemeFieldName,
    stylingModeFieldName: field.LookupTagStylingModeFieldName,
    formKey: formKey || `${field.formID}`,
    formID: field.formID,
    name: field.name,
    sortValues: field.sortValues,
    onInitFunction: callOnce(onInitFunction)
  });

  const displayExpr = field.displayField;
  const valueExpr = field.getKeyObjFieldName();

  if (field.isDualListSelector()) {
    dataSource = new ListSelectorDataSource({
      key: valueExpr,
      objectName: field.getLinkedName(),
      requestColumns: [valueExpr, displayExpr],
      searchField: displayExpr
    });
  }

  return {
    dataSource,
    displayExpr,
    valueExpr,
    dictObj: field.getLinkedName()
  };
};

function prepareFilterForSelect(filter: any) {
  let out: Record<string, any> = {};

  if (filter == null) {
    return out;
  }

  let FIELD = 0,
    /*OPER = 1,*/ VAL = 2;

  for (let fil of filter) {
    if (Array.isArray(fil)) {
      let fld = fil[FIELD],
        val = fil[VAL];

      if (out.hasOwnProperty(fld)) {
        out[fld].push(val);
      } else {
        Array.isArray(val) ? (out[fld] = val) : (out[fld] = [val]);
      }
    } else if (fil !== 'or') {
      let fld = filter[FIELD];
      out[fld] = filter[VAL];
    }
  }

  return out;
}

const getPageForRequest = (skip: number, take: number) => {
  return (skip + take) / take;
};

type IOnInitFunction = (_: {formID: string; formKey: string; name: string}) => any;

/**
 * если лукап открыт и пошел запрос на первую страницу - dispatch onInitFunction
 * у филдов и фильтров onInitFunction - присваивается в "see"
 */
function getLookUpObject({
  formID,
  formKey,
  name,
  onInitFunction,
  page
}: {
  formID: string;
  formKey: string;
  name: string;
  onInitFunction?: IOnInitFunction;
  page: number;
}) {
  if (typeof onInitFunction === 'function' && page === 1) {
    // onInitFunction может вызываться лишь раз
    // во второй раз она вернет undefined и в таком случае не нужно ничего диспатчить
    const actionCreator = onInitFunction({formID, formKey, name});

    if (actionCreator) {
      return store.dispatch(actionCreator);
    }
  }
  return Promise.resolve({});
}

// Фильтр приходит с юзер скриптов. Приходит в виде [{...}].
// Внутри массива всегда в 0 элементе объект который нужно скормить в запрос
export const applyFilterFromUserScript = (filter: [Record<string, any>], sourceFilter?: Record<string, any>) => {
  if (Array.isArray(filter) && filter[0] != null) {
    if (isObject(filter[0])) {
      sourceFilter = {...filter[0]};
    } else {
      sourceFilter = prepareFilterForSelect(filter);
    }
  }
  return sourceFilter;
};

async function getSelectFieldData({
  loadOptions,
  dictKeyFld,
  dictNameFld,
  dictDisplayFld,
  dictObj,
  colorSchemeFieldName,
  stylingModeFieldName,
  formID,
  formKey,
  name,
  sortValues,
  operationsParams,
  onInitFunction
}: ReactSelectFieldDSParam & {
  loadOptions: LoadOptions<any>;
}) {
  const displayFld = dictDisplayFld!;
  const dictObject = dictObj!;
  let fltr: Record<string, any> = {};
  let {filter, take, skip, searchValue, searchOperation} = loadOptions;
  /**
   * берем значение страницы для запроса
   * @type {number}
   */
  const page = getPageForRequest(skip!, take!);
  /**
   * формируем обьект лукап поля после пользовательского события
   * если выполнять здесь данное событие то при селектКонтроле в таблице ломается подгрузка данных
   * @see {onInitLookupField | onInitLookupFilter}
   */
  const {datasource: lookUpDataSource, filter: lookUpFilter} = await getLookUpObject({
    formID,
    formKey,
    name,
    onInitFunction,
    page
  });
  /**
   * Возвращаем DS если его заполнили в пользовательском событии
   */
  if (Array.isArray(lookUpDataSource) && lookUpDataSource.length) {
    return lookUpDataSource;
  }

  /**
   * присваиваем локальной переменной фильтр если его заполнили в пользовательском событии
   */
  if (lookUpFilter && !isEmptyObject(lookUpFilter)) {
    filter = [lookUpFilter];
  }

  /**
   * применяем фильтр, который приходит с юзер скриптов
   */
  fltr = applyFilterFromUserScript(filter, fltr)!;

  // if (searchValue != null && searchValue !== '') {
  //в селектбоксе если убрать значение поиска не отображаються все доступные элементы для выбора
  if (searchValue != null && searchValue !== '') {
    // @ts-ignore
    const {operation, value} = getLoadParams(searchValue)[searchOperation];
    fltr[displayFld] = {[operation]: value};
  }

  // при очищенні хрестиком або втраті фокуса
  // searchValue == null, але і обʼєкті fltr заишається старе значення для displayFld,
  // і список фільтрується по старому значенню
  if (displayFld !== dictKeyFld && searchValue == null && fltr[displayFld] && !lookUpFilter) {
    delete fltr[displayFld];
  }

  const columns = [dictNameFld!, dictKeyFld!, colorSchemeFieldName!, stylingModeFieldName!].filter(col => col);

  const sort = sortValues?.length ? sortValues : [displayFld];
  const response = await resultPostWithPromise({
    path: dictObject,
    columns: columns,
    filter: fltr,
    params: operationsParams && {Params: operationsParams},
    page: page,
    rowsPerPage: take!,
    sorts: sort
  });

  return response[dictObject].filter((item: Record<string, any>) => item[displayFld] != null);
}

async function getSelectSearchedFieldData({
  loadOptions,
  dictObject,
  columns,
  params
}: {
  loadOptions: LoadOptions;
  dictObject: string;
  columns: string[];
  params: {Params: Record<string, any>};
}) {
  let {take, searchValue} = loadOptions;
  const page = -1;

  if (!searchValue) return [];
  const response = await resultPostWithPromise({
    path: dictObject,
    columns: columns,
    filter: {
      [fields.Title]: {like: searchValue},
      [fields.IsGroup]: {'=': 0},
      ...getUIVersionLS()
    },
    page: page,
    rowsPerPage: take!,
    sorts: defaultSorts,
    params: {...params}
  });

  return response[dictObject];
}

export {
  reactSelectFieldDS,
  createFieldDataSource,
  getPageForRequest,
  getSelectFieldData,
  reactSelectSearchFieldDS,
  resultPostWithPromise
};

import {IButtonOptions, ILayoutItem, IReportList} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {FormButton} from 'utilsOld/systemObjects';
import {createFilterTemplateButtons, createReportGroupButton, createSystemButton} from './utils';
import {isMobile} from 'utilsOld/device';
import {system} from 'services/objects';
import {DefaultButtonOptions} from 'utilsOld/toolbar_buttons';
import {IFilterTemplate} from '../../filterTemplates/interfaces';

const {
  SYSTEM_BUTTONS: {
    EXPORT_SELECTED,
    COPY_FILTER_AS_URL,
    EXPORT_ALL,
    TOGGLE_TOTALS_BY_SELECTED,
    TOGGLE_TOTALS_ALL_DATA,
    TOGGLE_TOTALS
  }
} = system;

const toggleTotalsArr = [
  {
    ID: DefaultButtonOptions[TOGGLE_TOTALS].id,
    name: TOGGLE_TOTALS,
    visible: isMobile(),
  },
  {
    ID: DefaultButtonOptions[TOGGLE_TOTALS_BY_SELECTED].id,
    name: TOGGLE_TOTALS_BY_SELECTED,
    visible: true
  },
  {
    ID: DefaultButtonOptions[TOGGLE_TOTALS_ALL_DATA].id,
    name: TOGGLE_TOTALS_ALL_DATA,
    visible: true
  },
];

const exportButtonNamesArr = [
  {ID: DefaultButtonOptions[EXPORT_ALL].id, name: EXPORT_ALL},
  {ID: DefaultButtonOptions[EXPORT_SELECTED].id, name: EXPORT_SELECTED}
];

/**
 * создание кнопок EXPORT_ALL, EXPORT_SELECTED
 * @param {function} onClickCustom
 * @param {FormButton} parentButton
 * @returns {[]}
 */
export const exportButton = (onClickCustom: any, parentButton: FormButton): ILayoutItem<IButtonOptions>[] => {
  return exportButtonNamesArr.map(({name}) =>
    createSystemButton({
      name,
      parentButton,
      onClickCustom
    })
  );
};

/**
 * создание кнопок TOGGLE_TOTALS
 * @param {function} onClickCustom
 * @param {FormButton} parentButton
 * @returns {[]}
 */
export const toggleTotalsButton = (onClickCustom: any, parentButton: FormButton): ILayoutItem<IButtonOptions>[] => {
  return toggleTotalsArr
    .filter(({visible}) => visible)
    .map(({name}) =>
      createSystemButton({
        name,
        parentButton,
        onClickCustom
      })
    );
};

/**
 * создание кнопок FILTER_MANAGER
 * @param {function} onClickCustom
 * @param {FormButton} parentButton
 * @returns {[]}
 */
export const filterButton = (onClickCustom: any, parentButton: FormButton): ILayoutItem<IButtonOptions>[] => {
  const filterButtonNamesArr = [{id: DefaultButtonOptions[COPY_FILTER_AS_URL].id, name: COPY_FILTER_AS_URL}];

  return filterButtonNamesArr.map(({name}) =>
    createSystemButton({
      name,
      parentButton,
      onClickCustom
    })
  );
};

/**
 * создание кнопок типа __selectFilterTemplate
 * @param {IFilterTemplate} filterTemplates
 * @param {FormButton} parentButton
 * @param {function} onClickCustom
 */
export const filterTemplateButtons = (
  filterTemplates: IFilterTemplate[],
  parentButton: ILayoutItem<IButtonOptions>,
  onClickCustom: () => void
): ILayoutItem<IButtonOptions>[] =>
  (filterTemplates || []).map(({id, name}: {id?: any; name: string}, index: number) => {
    return createFilterTemplateButtons({name, parentButton, onClickCustom, id, index});
  });

export const reportGroupButtons = (
  reportList: IReportList[],
  parentButton: ILayoutItem<IButtonOptions>,
  onClickCustom: () => void
): ILayoutItem<IButtonOptions>[] =>
  (reportList || []).map(
    (
      {
        id,
        name,
        dialogActionFormName,
        dialogActionFormID,
        title
      }: {id: number; name: string; dialogActionFormName?: string; dialogActionFormID?: string; title: string},
      index: number
    ) => {
      return createReportGroupButton({
        name,
        parentButton,
        dialogActionFormName,
        dialogActionFormID,
        onClickCustom,
        id,
        index,
        title
      });
    }
  );

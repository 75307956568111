import {SNACKBAR_TYPES} from '../interfaces/global-interfaces';

export enum SnackBarCreator {
  Form = 'form',
}

export interface SnackbarObject {
  id?: string;
  type: SNACKBAR_TYPES;
  message: string;
  title?: string;
  docUrl?: string;
  messageTimeout? :number;
  creator?: SnackBarCreator;
  responseId?: string;
  codeError?: string;
  detailError?: string;
  position?: 'center' | 'default';
}

export interface SnackbarState {
  snackbars: SnackbarObject[];
  lastSnackbar: SnackbarObject;
}

export interface SnackbarTimeoutMessagesProps {
  type: SNACKBAR_TYPES;
  codeError?: string;
  userTimeoutsConfig?: Record<string, any>
}

import {fields, system} from 'services/objects';
import {LanguageCode, Locale} from '../interfaces/global-interfaces';
import AppStateStorage from '../../utilsOld/settingsStorage/AppStateStorage';
import {getOldReturnUrl} from '../../utilsOld/returnUrl';
import {isEmptyObject} from '../../utilsOld/helpers';

/**
 * !!!!!!!!!Внимание в этот модуль нельзя подключать node_modules
 */

if (!getApplicationLang()) {
  setApplicationLang(LanguageCode.ua);
}

export function _createSettingName(name: string) {
  return name.toLowerCase();
}

export function setSettings(name: string, value: unknown) {
  let appSets = JSON.parse(localStorage.getItem(AppStateStorage.lsName()) || '{}');
  appSets[_createSettingName(name)] = value;
  localStorage.setItem(AppStateStorage.lsName(), JSON.stringify(appSets));
}

export function setSettingsToSessionStorage(name: string, value: unknown) {
  let appSets = JSON.parse(sessionStorage.getItem(AppStateStorage.lsName()) || '{}');
  appSets[_createSettingName(name)] = value;
  sessionStorage.setItem(AppStateStorage.lsName(), JSON.stringify(appSets));
}

export function getSettings() {
  return JSON.parse(localStorage.getItem(AppStateStorage.lsName()) || '{}');
}

export function getSettingsFromSessionStorage() {
  return JSON.parse(sessionStorage.getItem(AppStateStorage.lsName()) || '{}');
}

export function setRegionalSettings(value: Record<any, any>) {
  const regionalSettingsName = _createSettingName(system.USER_SETTINGS.REGIONAL_SETTINGS);
  let regionalSets = JSON.parse(localStorage.getItem(regionalSettingsName) || '{}');
  regionalSets[regionalSettingsName] = value;
  localStorage.setItem(regionalSettingsName, JSON.stringify(regionalSets));
}

export function getRegionalSettingsFromLS() {
  return JSON.parse(localStorage.getItem(_createSettingName(system.USER_SETTINGS.REGIONAL_SETTINGS)) || '{}');
}

export function clearLocalAndSessionStorage() {
  const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}');
  const keysToDelete = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)!;
    const parsedUserId = +key.split('-')[0];
    if (parsedUserId === currentUser.UserID) {
      keysToDelete.push(key);
    }
  }

  keysToDelete.forEach(key => {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  });
}

export function getRegionalSettings() {
  const regionalSettings = getRegionalSettingsFromLS()[_createSettingName(system.USER_SETTINGS.REGIONAL_SETTINGS)];
  if (!regionalSettings || !Locale.hasOwnProperty(regionalSettings[fields.RegionalLocale])) {
    return {[fields.RegionalLocale]: Locale.uk};
  }
  return regionalSettings;
}

export const convertedDataResponse = (response: any, login: string, additionalUserData: {Email: string}) => {
  return {
    name: response[system.SYS_LOGIN].Name,
    login,
    ...response[system.SYS_LOGIN],
    ...additionalUserData
  };
};

export function setApplicationLang(value: any) {
  const applicationLangName = _createSettingName(system.USER_SETTINGS.APPLICATION_LANG);
  let applicationLangSets = JSON.parse(localStorage.getItem(applicationLangName) || '{}');
  applicationLangSets[applicationLangName] = value;
  localStorage.setItem(applicationLangName, JSON.stringify(applicationLangSets));
}

export function getApplicationLang() {
  const applicationLangName = _createSettingName(system.USER_SETTINGS.APPLICATION_LANG);
  return JSON.parse(localStorage.getItem(applicationLangName) || '{}')[applicationLangName];
}

export function getNewWindowOpenedMode() {
  return getSettings()[_createSettingName(system.USER_SETTINGS.NEW_WINDOW_OPENED_MODE)];
}

export function getAppAltColor() {
  return getSettings()[_createSettingName(system.USER_SETTINGS.ALTER_LINES_COLOR)];
}

export function getAppHorizSeparLines() {
  return getSettings()[_createSettingName(system.USER_SETTINGS.HORIZ_SEPAR_LINES)];
}

export function getAppVertSeparLines() {
  return getSettings()[_createSettingName(system.USER_SETTINGS.VERT_SEPAR_LINES)];
}

export function getFocusOnDataMode() {
  return getSettingsFromSessionStorage()[_createSettingName(system.USER_SETTINGS.FOCUS_ON_DATA_MODE)];
}

export const getKeyForAppSettings = (userID?: number | null) => {
  const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}');
  return new AppStateStorage({userID: currentUser.UserID || userID}).lsName;
};

export const checkAppSettings = () => {
  const key = getKeyForAppSettings();
  const appSetts = JSON.parse(localStorage.getItem(key) || '{}');
  if (isEmptyObject(appSetts)) {
    const oldAppSetts = JSON.parse(localStorage.getItem(system.USER_SETTINGS.APP_SETTINGS) || '{}');
    const returnUrl = getOldReturnUrl();
    localStorage.setItem(key, JSON.stringify({...oldAppSetts, ...returnUrl}));
  }
};

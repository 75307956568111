import {resultPostWithPromise} from '../../../../utilsOld/createFieldDataSource';
import {postWithPromise} from '../../../../services/requests/baseRequests';
import {wapiUrl} from '../../../../services/baseUrl';
import {prepareObjectSave} from '../../../../services/currentForms/prepareObjectSave';
import {SysFormWrapper} from '../../../../utilsOld/systemObjects';
import DataSource from 'devextreme/data/data_source';
import store from '../../../../store';
import {userScriptActions} from '../../../../services/currentForms/userScriptActions';
import FormEventQueue, {FormEventQueueEvents} from '../../../../utilsOld/formEventQueue/FormEventQueue';
import {prepareFilterForRequest} from './utils';
import {isEmptyValue} from '../../../../services/SecondaryMethods/typeUtils';
import {SchedulerDataSource} from '../../../widgets/Scheduler/types';

interface IProps {
  dictObj: string;
  columns?: string[];
  filter?: {[key: string]: any};
  sorts?: string[];
}

interface IPropsDS extends IProps {
  formID: string;
  formKey: string;
  eventQueue: FormEventQueue;
}

async function fetchData({dictObj, columns = [], filter = {}, sorts = []}: IProps) {
  const response = await resultPostWithPromise({
    path: dictObj,
    columns: columns,
    filter,
    page: 1,
    rowsPerPage: 500,
    sorts
  });

  return response[dictObj];
}

interface IModInDataSourceProps {
  formData: Record<string, any>;
  objectName: string;
  formDataIDs: number[];
  keyField: string;
  sysForm: SysFormWrapper;
  operation?: string;
  operationsParams?: any;
}

async function modInDataSource({
  formData,
  objectName,
  formDataIDs,
  keyField,
  sysForm,
  operation = 'Mod',
  operationsParams
}: IModInDataSourceProps) {
  const {headers, data} = prepareObjectSave({
    data: formData,
    appObject: objectName,
    ids: formDataIDs,
    keyField: keyField,
    sysForm: sysForm,
    operation: operation,
    operationsParams: operationsParams
  });

  // @ts-ignore
  const {error} = await postWithPromise({
    data,
    headers,
    url: `${wapiUrl}/${objectName}/${operation}`
  }).then(response => ({
    ...response,
    initialData: {...formData}
  }));

  return !!error;
}

function getDataSource({dictObj, columns = [], formID, formKey, eventQueue}: IPropsDS) {
  const newDataSource: SchedulerDataSource = new DataSource({
    load: async function (loadOptions) {
      let fltr = prepareFilterForRequest(loadOptions.filter[0]);

      if (newDataSource.shouldClearData) {
        return [];
      }

      if (newDataSource.preventLoad) {
        newDataSource.preventLoad = false;
        return newDataSource.arrResult;
      }

      if (newDataSource.apiFilter) {
        // @ts-ignore
        fltr = {...newDataSource.apiFilter, ...fltr};
      }

      const result = await fetchData({
        dictObj,
        columns: columns.filter(col => !isEmptyValue(col)),
        filter: fltr
      });

      newDataSource.arrResult = result;
      eventQueue?.addHandHandling({
        name: FormEventQueueEvents.ON_DATA_LOADED,
        job: () =>
          //@ts-ignore
          store.dispatch(
            userScriptActions.onDataLoaded({
              formID,
              formKey
            })
          )
      });

      return result;
    },
    remove: key => Promise.resolve(key),
    update: () => Promise.resolve()
  });

  return newDataSource;
}

export {fetchData, modInDataSource, getDataSource};

import {system} from 'services/objects';

export const tableIdToFormKey = (tableId: string, userId: string | number) => {
  const prefix = `${userId}-table-`;
  if (!tableId.startsWith(prefix)) {
    throw new Error('Invalid input format');
  }

  const content = tableId.slice(prefix.length);
  const parts = content.split('-');

  const [formID, parentFormID] = parts;
  if (!formID) {
    throw new Error('No valid IDs found in the input string');
  }

  if (parentFormID) {
    return `${formID}/${parentFormID}`;
  }

  return formID;
};

export function createTableID(formID: string, parentFormID?: string | null, userID?: number) {
  const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}');
  userID = userID || currentUser.UserID;

  if (userID == null) {
    let err = new Error('userID is undefined. Check function createTableID');
    err.name = system.USER_NOT_FOUND;
    throw err;
  }
  let id = createLSKey(userID, 'table', formID);
  if (parentFormID != null) {
    id += `-${parentFormID}`;
  }
  return id;
}

export const createLSKey = (userID: number, text: string, formKey: string): string => {
  return `${userID}-${text}-${formKey}`;
};

export const createKanbanStorageKey = (userId: number, formKey: string) => {
  const keyText = 'kanban';
  return createLSKey(userId, keyText, formKey);
};

export const createKanbanColumnsStorageKey = (userId: number, formKey: string) => {
  const keyText = 'kanban-columns';
  return createLSKey(userId, keyText, formKey);
};

export const createFormStorageKey = (userId: number, formKey: string) => {
  const keyText = 'formKey';
  return createLSKey(userId, keyText, formKey);
};

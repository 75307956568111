import {
  FILTER_OPERATIONS,
  FormCreateMode,
  FormType,
  FormViewMode,
  TOOLBAR_VIEW_TYPE
} from '../interfaces/global-interfaces';
import {IFocusedField, IGroupOptions, ILayoutItem, LayoutButtons} from '../SecondaryMethods/formItems/itemInterfaces';
import {ExtendedPromise} from '../SecondaryMethods/extendedPromise';
import FormEventQueue from 'utilsOld/formEventQueue/FormEventQueue';
import {FilterField, FormField} from 'utilsOld/systemObjects';
import DataWorker, {TableDataWorkerResponse} from 'DataWorker';
import {FormEvents} from 'middlewares/userScript/inner-interfaces';
import D5DataSource from 'utilsOld/datasource/D5DataSource';

export const DEFAULT_ROW_PER_PAGE = 200;
export const DEFAULT_ROW_PER_PAGE_TREE = 100000;

export interface FocusOnState {
  rowIndex: number;
  columnIndex: number;
  cellValue: any;
  fieldName: string;
  data: Record<string, any>;
  key: any;
}

export interface CurrentFormState {
  editorButtons?: LayoutButtons;
  actionFormId: string | null;
  objectName: string | null;
  parentFormID: string | null;
  title: string;
  id: null | any;
  formType: FormType;
  formStatus: string | null;
  createMode: FormCreateMode | null;
  dataSource: D5DataSource | null;
  events: FormEvents;
  items: ILayoutItem<any>[];
  groups: ILayoutItem<IGroupOptions>[];
  editableRowData: Record<string, any> | null;
  selectedRows: any[];
  parentFormSelectedRows: any[];
  selectedRowKeys: any[];
  toolBarViewType: TOOLBAR_VIEW_TYPE;
  rowsPerPage: number;
  formData: Record<string, any>;
  parentFormFilter?: Record<string, any>;
  closePromise: ExtendedPromise | Record<any, any>;
  formKey: string;
  parentFormKey: string;
  focusOn: FocusOnState;
  isModified: boolean;
  isUserModified: boolean | undefined;
  silentClosing: boolean;
  isModifying: boolean;
  isShowTitle: boolean;
  isAutoRefresh: boolean;
  isSubForm: boolean;
  addAndCreateOneMoreElem?: boolean;
  userData: any | null;
  readOnly: boolean;
  disabled: boolean;
  buttonType: number | null;
  eventQueue: FormEventQueue;
  operationsParams?: Record<string, any>;
  initialFormData?: Record<string, any>;
  dataWorker?: DataWorker<TableDataWorkerResponse>;
  viewMode?: FormViewMode;
  isFixedOrder: boolean;
  firstOpen: boolean;
  subsystemName?: string;
  focusedItem?: IFocusedField;
  subTitle?: string;
  hint: string;
  pagesPredicted?: number;
  options?: Record<string, any>;
  apiOperation: {
    list: string;
    mod: string;
    ins: string;
    del: string;
  };
}

export type RelativeField = {
  value: any;
  fieldName: string;
};

export type OnFilterValueChanged = (_: {
  value: any;
  operation: FILTER_OPERATIONS;
  field: FormField;
  displayValue?: string;
  sender?: string;
  relativeField?: RelativeField;
  hasIsBlank?: boolean;
}) => any;

export type OnValueChangedInControl = (_: {value: any; displayValue?: string; relativeField?: RelativeField}) => any;

export type HeaderFilterModalOptions = {
  filterField?: FilterField;
  filterName: string;
  tableField: FormField;
  target: HTMLElement;
  initialValue: any;
  initialOperation: FILTER_OPERATIONS;
  defaultHasIsBlank: boolean;
};

export type TBButtonOptions = {
  text: string;
  customText: string
  stylingMode: string;
  position: 'topBar' | 'bottomBar';
  type: string;
  icon?: string;
  visible?: boolean;
  disabled?: boolean;
  className: string;
  elementAttr: {id: string};
  id: string;
  onClick: any;
};
export type TBButtonConfig = {
  locateInMenu: string;
  location: string;
  widget: string;
  position: string;
  visible: boolean;
  options: Partial<TBButtonOptions>;
};

export type SystemToolbarButtonName =
  | 'clear'
  | 'cancel'
  | 'saveClose'
  | 'closeIcon'
  | 'save'
  | 'saveAndCreateNew'
  | 'menu'
  | 'user'
  | 'home'
  | 'apply'
  | 'select';

import {TVLocalStorageColumn} from '../../components/TableCoreOld/TableView';
import {booleanToNumber, numberToBoolean} from './utils';
import {USmallInt} from '../../services/interfaces/global-interfaces';

export interface MinimizeColumn {
  //groupID
  gid: number | null;
  //groupParentID
  gpid: number | null;
  //index
  i: number;
  //fixed
  f?: USmallInt;
  //fixedPosition
  fp?: 'left' | 'right';
  //width
  w?: string | number;
  //sortOrder
  so?: 'asc' | 'desc';
  //sortIndex
  si?: number;
  //visibleIndex
  vi?: number;
  //visible
  v?: USmallInt;
  //isVisibleOnEditDockPanel
  ivoedp?: USmallInt;
  //dataField
  df?: string;

  //Только у динамических колонок
  //isDynamicColumn
  idc: USmallInt;
  //Только у динамических колонок для управления отображением
  //wasHiddenByUser
  whbu?: USmallInt;
  //userCreated
  uc?: USmallInt;
  //isCombinedColumn
  icc?: USmallInt;
}

type MaximizeColumns = TVLocalStorageColumn[];
type MinimizeColumns = MinimizeColumn[];

export const isColumnsMinimized = (columns: MaximizeColumns | MinimizeColumns): boolean => {
  return columns.some(col => ('i' in col || 'gid' in col || 'gpid' in col || 'df' in col));
};

export const minimizeColumns = (columns: MaximizeColumns | MinimizeColumns): MinimizeColumn[] => {
  if (isColumnsMinimized(columns)) return columns as MinimizeColumns;

  return (columns as MaximizeColumns).map(col => {
    return {
      gid: col.groupID,
      gpid: col.groupParentID,
      i: col.index,
      f: booleanToNumber(col.fixed),
      fp: col.fixedPosition,
      w: col.width,
      so: col.sortOrder,
      si: col.sortIndex,
      vi: col.visibleIndex,
      v: booleanToNumber(col.visible),
      ivoedp: booleanToNumber(col.isVisibleOnEditDockPanel),
      df: col.dataField,
      idc: booleanToNumber(col.isDynamicColumn) as USmallInt,
      whbu: booleanToNumber(col.wasHiddenByUser),
      uc: booleanToNumber(col.userCreated),
      icc: booleanToNumber(col.isCombinedColumn)
    };
  });
};

export const maximizeColumns = (columns: MinimizeColumns): MaximizeColumns => {
  return columns.map(col => ({
    groupID: col.gid,
    groupParentID: col.gpid,
    index: col.i,
    fixed: numberToBoolean(col.f),
    fixedPosition: col.fp,
    width: col.w,
    sortOrder: col.so,
    sortIndex: col.si,
    visibleIndex: col.vi,
    visible: numberToBoolean(col.v),
    isVisibleOnEditDockPanel: numberToBoolean(col.ivoedp),
    dataField: col.df,
    isDynamicColumn: numberToBoolean(col.idc) as boolean,
    wasHiddenByUser: numberToBoolean(col.whbu),
    userCreated: numberToBoolean(col.uc),
    isCombinedColumn: numberToBoolean(col.icc)
  }));
};

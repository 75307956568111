import {SysForm} from 'services/interfaces/sysObjects';
import ColumnsBuilder from 'components/TableCoreOld/columns/ColumnsBuilder';
import {fields, system} from 'services/objects';
import {getSysField} from 'utilsOld/sysFormUtils';
import StateManager from 'components/TableCoreOld/StateManager';
import {GroupType} from 'services/interfaces/global-interfaces';
import {getCombinedColumnFields} from './columns/combinedColumn/combinedColumnsUtils';
import {SysFormType} from '../../utilsOld/systemObjects';
import {isSystem} from '../../services/tables/mergeColumnOptions';
import {TVLocalStorageColumn} from './TableView';

function isColumnSpacerVisible(columns: any[]) {
  return columns.filter(col => !isSystem(col) && col.visible).length > 1;
}

export function toggleColumnSpacer(columns: any[]) {
  const spacerCol = columns.find(col => col.name === getSysField(system.ColumnSpacer));
  //якщо тільки одна видима колонка, то не потрібно спейсер додавати
  spacerCol.visible = isColumnSpacerVisible(columns);
}

export function buildColumns({
  sysForm,
  regSetting,
  isNestedTable,
  maxLineCount,
  parentFormID,
  detailObjKeyFieldName,
  formKey,
  lsColumns,
}: {
  isNestedTable: boolean;
  maxLineCount: number;
  regSetting: string;
  detailObjKeyFieldName: string | null;
  sysForm: SysForm;
  parentFormID: string | null;
  formKey: string;
  lsColumns: TVLocalStorageColumn[];
}) {
  const viewSource = sysForm.Sys_FormFields;

  const parentFieldName = sysForm[fields.ParentFieldID_Name],
    parentFieldType = sysForm[fields.ParentFieldID_Type],
    sysParentFieldName = parentFieldName && getSysField(parentFieldName);

  /**
   * Отображать фильтры нужно только там где поле объекта совпадает с полем объекта фильтра.
   * + detailObjKeyFieldName
   */
  const forbidFiltering = () => {
    const result: string[] = viewSource
      .filter(field => {
        return !sysForm.Sys_FormFilterFields?.some(
          filter => filter[fields.ObjectFieldID_Name] === field[fields.ObjectFieldID_Name]
        );
      })
      .map(field => field[fields.ObjectFieldID_Name]);

    if (detailObjKeyFieldName) {
      result.push(detailObjKeyFieldName);
    }
    return result;
  };

  const builder = new ColumnsBuilder({
    maxLineCount,
    keyField: sysForm[fields.ObjectID_KeyObjectFieldID_Name],
    keyFieldType: sysForm[fields.ObjectID_KeyObjectFieldID_FieldType],
    systemKeyField: getSysField(sysForm[fields.ObjectID_KeyObjectFieldID_Name]),
    checkFieldID: sysForm[fields.CheckField],
    viewSource,
    regSetting,
    isFixedColumnOrder: sysForm.IsFixedColumnOrder,
    isShowInlineButtons: sysForm.IsShowInlineButtons,
    isFixedOrder: sysForm.IsFixedOrder,
    combinedColumnFields: getCombinedColumnFields(sysForm),
    objectName: sysForm[fields.ObjectID_Name],
    editMode: sysForm.EditMode,
    isNestedTable,
    parentFormID,
    fixedOrderField: new SysFormType(sysForm[fields.Type]).isList() ? sysForm[fields.FixedOrderField] : undefined,
    forbidFiltering: forbidFiltering(),
    parentFieldType,
    sysParentFieldName,
    formKey,
  });

  let columns = builder.prepareColumns();
  columns = StateManager.applyStorageSettingsToColumns(lsColumns || [], columns);
  toggleColumnSpacer(columns);

  const groups = sysForm.Sys_FormGroups?.filter(group => group.GroupType === GroupType.TABLE_HEADER);
  return builder.buildGroups(columns, builder.sysGroupsToColumn(groups));
}
